import React from "react";

import classNames from "classnames";

import Typography from "../typography/index.component";

import "./index.component.scss";

export default function Divider(props) {
  // props
  const { variant, showCaption, captionText, styles, className } = props;

  return (
    <div
      style={{ ...styles }}
      className={classNames([
        "divider-wrapper",
        className,
        {
          vertical: variant === "vertical",
          horizontal: variant === "horizontal",
        },
      ])}
    >
      {showCaption && (
        <Typography variant="h4" className="divider-caption">
          {captionText}
        </Typography>
      )}
    </div>
  );
}
