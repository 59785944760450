import React from "react";

export default function ShareIcon({ fill, size, className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      className={className}
    >
      <defs>
        <path
          id="at8cqza4ja"
          d="M16.875 13.75c-.882 0-1.678.366-2.246.954l-8.418-4.21c.025-.16.039-.326.039-.494 0-.168-.014-.334-.04-.495l8.419-4.209c.568.588 1.364.954 2.246.954C18.601 6.25 20 4.85 20 3.125 20 1.399 18.6 0 16.875 0c-1.726 0-3.125 1.4-3.125 3.125 0 .168.014.334.04.495L5.37 7.829c-.568-.588-1.364-.954-2.246-.954C1.399 6.875 0 8.275 0 10c0 1.726 1.4 3.125 3.125 3.125.882 0 1.678-.366 2.246-.954l8.418 4.21c-.026.163-.039.328-.039.494 0 1.726 1.4 3.125 3.125 3.125C18.601 20 20 18.6 20 16.875c0-1.726-1.4-3.125-3.125-3.125z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <g>
                <g transform="translate(-1374 -236) translate(850 158) translate(510 64) translate(14 14) translate(.333)">
                  <mask id="wol4mmjmob" fill="#fff">
                    <use xlinkHref="#at8cqza4ja" />
                  </mask>
                  <use fill="#000" fillRule="nonzero" xlinkHref="#at8cqza4ja" />
                  <path
                    fill={fill}
                    d="M-0.333 0H19.667V20H-0.333z"
                    mask="url(#wol4mmjmob)"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
