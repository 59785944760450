import React from "react";
export default function FacebookIcon({ fill, size, className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      className={className}
    >
      <defs>
        <path
          id="4ijw5c11ta"
          d="M8 .14C3.667.14.14 3.663.14 8c0 1.44.392 2.846 1.14 4.075L.155 15.446c-.038.113-.007.239.076.32.06.063.141.095.223.095.034 0 .066-.006.1-.016l3.371-1.125c1.23.748 2.635 1.14 4.075 1.14 4.336 0 7.861-3.527 7.861-7.86 0-4.336-3.525-7.86-7.86-7.86zm.864 12.609c0 .143-.117.26-.26.26H7.397c-.144 0-.26-.117-.26-.26V11.54c0-.143.116-.26.26-.26h1.207c.143 0 .26.117.26.26v1.208zm.245-3.61c-.149.058-.245.18-.245.313v.885c0 .143-.117.26-.26.26H7.397c-.144 0-.26-.117-.26-.26v-.885c0-.861.538-1.618 1.37-1.928.586-.219.963-.788.94-1.416-.026-.739-.65-1.363-1.39-1.39L8 4.717c-.377 0-.734.143-1.004.406-.287.276-.445.646-.445 1.044 0 .143-.115.26-.26.26H5.086c-.143 0-.26-.117-.26-.26 0-.872.346-1.684.973-2.287.624-.601 1.457-.92 2.323-.885 1.648.062 2.987 1.402 3.05 3.05.052 1.373-.775 2.617-2.063 3.096z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <g>
                <g transform="translate(-32.000000, -460.000000) translate(16.000000, 112.000000) translate(16.000000, 348.000000)">
                  <circle cx="16" cy="16" r="16" fill={fill} fillOpacity="1" />
                  <g transform="translate(8.000000, 8.000000)">
                    <mask id="fktyczmc1b" fill="#fff">
                      <use xlinkHref="#4ijw5c11ta" />
                    </mask>
                    <use
                      fill="#000"
                      fillRule="nonzero"
                      xlinkHref="#4ijw5c11ta"
                    />
                    <path
                      fill="#000"
                      fillOpacity=".16"
                      d="M-8 -8H24V24H-8z"
                      mask="url(#fktyczmc1b)"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
