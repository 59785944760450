import React from "react";

export default function HomeIcon({ fill, className }) {
  return (
    <svg
      width="16"
      height="16"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <path
          id="a"
          d="m8 .8 8 7.966h-1.542V16h-5.65v-4.823H7.192V16H1.542V8.766H0z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h16v16H0z" />
        <mask id="b" fill="#fff">
          <use xlinkHref="#a" />
        </mask>
        <use fill="#000" fillRule="nonzero" xlinkHref="#a" />
        <path fill={fill} mask="url(#b)" d="M0 0h16v16H0z" />
      </g>
    </svg>
  );
}
