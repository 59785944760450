import React, { Fragment, useContext } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { BaseContext } from "../../context/BaseContext";
import ScreenHelper from "../../helpers/screen.helper";

import configSelector from "common/store/selectors/configSelector";
import pageSelector from "common/store/selectors/pageSelector";
import HomeIcon from "../../assets/icons/home.icon";
import MenuIcon from "../../assets/icons/menu.icon";
import Typography from "../typography/index.component";

import "./index.component.scss";

export default function Navigator({
  config,
  location,
  customRoutes,
  toggleDrawerCallback,
}) {
  // variables
  const { menuRoute, landingScreenEnabled, isQrModeEnabled } =
    useContext(BaseContext);
  const isHomeRoute = ScreenHelper.isMenuScreen(
    customRoutes,
    location.pathname,
    landingScreenEnabled
  );
  const isCartScreen = ScreenHelper.isCartScreen(location.pathname);
  const isCheckoutScreen = ScreenHelper.isCheckoutScreen(location.pathname);

  const primaryColor = configSelector.getPrimaryColor({ config });
  const primaryTextColor = configSelector.getPrimaryTextColor({ config });
  const menuPageConfig = pageSelector.getMenuPage({ config });
  const { t } = useTranslation();

  if (isCartScreen || isCheckoutScreen) {
    return <Fragment />;
  }

  return (
    <div className="navigator-wrapper">
      {menuPageConfig.enabled && !isQrModeEnabled && (
        <NavLink
          className="navigator-tab"
          activeClassName="active-navigator-tab"
          to={menuRoute}
        >
          <HomeIcon
            className="tab-icon"
            fill={isHomeRoute ? primaryColor : "#A3A3A4"}
          />
          <Typography
            variant="h2"
            weight="bold"
            className="tab-name"
            style={{ opacity: isHomeRoute ? 1 : 0.32, marginBottom: "-2px" }}
            fontColor={isHomeRoute ? primaryColor : primaryTextColor}
          >
            {t("navigator.home")}
          </Typography>
        </NavLink>
      )}
      <div className="navigator-tab" onClick={toggleDrawerCallback}>
        <MenuIcon opacity="0.32" fill={"#000000"} className="tab-icon" />
        <Typography
          variant="h2"
          weight="bold"
          className="tab-name"
          fontColor={primaryTextColor}
          style={{ opacity: 0.32 }}
        >
          {t("navigator.more")}
        </Typography>
      </div>
    </div>
  );
}
