import React, { useEffect, useState, useContext, useMemo } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";

import i18next from "i18next";
import clone from "common/helpers/deepClone";
import { bindActionCreators } from "redux";

import bannerChooser from "common/helpers/bannerChooser";
import seoHelper from "common/helpers/seoHelper";
import upsdkService from "common/services/upsdkService";
import { fetchLikes, likeItem, unlikeItem } from "common/store/actions/likes";
import configSelector from "common/store/selectors/configSelector";
import itemSelector from "common/store/selectors/itemSelector";
import pageSelector from "common/store/selectors/pageSelector";
import upsdkSelector from "common/store/selectors/upsdkSelector";

import ImageCarousel from "../../common/image-carousel/index.component";
import FloatingCart from "../../common/floating-cart/index.component";
import ItemList from "../../common/item-list/index.component";
import { BaseContext } from "../../context/BaseContext";
import renderHelmet from "../../helpers/helmetHelper";
import { translateOptions } from "../../i18n";
import "./index.component.scss";

function Explore(props) {
  const [banners, setBanners] = useState(window.BANNER_IMAGES || []);
  const BaseConsumer = useContext(BaseContext);
  const [recommendedApiData, setRecommendedApiData] = useState([]);
  const [recommendedLoading, setRecommendedLoading] = useState(false);
  const [bannerApiLoaded, setBannerApiLoaded] = useState(false);
  const [recommendedError, setRecommendedError] = useState(false);

  // props
  const {
    config,
    selectedStore,
    catalogueLoading,
    categories,
    searchResults,
    appliedOptions,
    primaryTextColor,
    catalogueError,
    fulfillmentType,
    items,
    cart,
  } = props;

  // variables
  const { isMobileView, screenWidth, searchActive } = BaseConsumer;
  const pageData = pageSelector.getMenuPage({ config });
  const seoData = seoHelper(pageData.seo);
  const location_id = selectedStore ? selectedStore.id : null;
  const primaryColor = configSelector.getPrimaryColor({ config });
  const showRecommendedItemsWidget = itemSelector.showRecommendedItemsWidget({
    config,
  });
  const activeLanguage = i18next.language;

  // callbacks
  const { t } = props;

  const filterSearchResults = (searchItems) => {
    /**
     * Temp fix
     * Need to get this fixed from codex/meraki-api
     */
    const filteredSearchItems = [];
    items.forEach((item) => {
      let vals = searchItems.filter((o) => o.id === item.id);
      if (vals.length > 0) {
        filteredSearchItems.push(vals[0]);
      }
    });
    return filteredSearchItems;
  };

  useEffect(() => {
    new Promise((res, rej) => {
      upsdkService
        .getBanners()
        .then((response) => {
          setBanners(response.data);
          res();
        })
        .catch(() => rej());
    }).finally(() => {
      setBannerApiLoaded(true);
    });
  }, [activeLanguage]);

  const bannersForDisplay = useMemo(() => {
    return bannerChooser(banners);
  }, [banners]);

  useEffect(() => {
    if (!showRecommendedItemsWidget) return;

    setRecommendedLoading(true);
    upsdkService
      .getRecommendedItems([0], location_id)
      .then((response) => {
        if (response) {
          setRecommendedApiData(response);
        }
      })
      .catch(() => {
        setRecommendedError(true);
      })
      .finally(() => {
        setRecommendedLoading(false);
      });
  }, [
    location_id,
    setRecommendedApiData,
    showRecommendedItemsWidget,
    activeLanguage,
    fulfillmentType,
  ]);

  useEffect(() => {
    if (selectedStore) {
      const { lat, lng } = selectedStore;
      upsdkService.refreshSelectedStore(lat, lng, fulfillmentType);
    }

    function recordPositionBeforeReload() {
      sessionStorage.setItem("scrolledSoFar", 0);
      sessionStorage.setItem("mobileScrollPosition", 0); // for mobile view autoscroll due to keyboard
    }

    window.addEventListener("beforeunload", recordPositionBeforeReload);
    return () => {
      window.removeEventListener("beforeunload", recordPositionBeforeReload);
    };

    // TODO: Need to fix
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let recommended = recommendedApiData;

  if (appliedOptions && appliedOptions.filters) {
    if (appliedOptions.sort_by || appliedOptions.filters.length > 0) {
      recommended = [];
    }
  }

  let finalData = clone(categories) || [];
  if (showRecommendedItemsWidget && recommended && recommended.length > 0) {
    finalData.unshift({
      id: "recommended",
      name: t("common.recommended"),
      slug: "recommended",
      items: recommended,
      item_count: recommended.length,
      sub_categories: [],
    });
  }

  if (searchResults && searchActive) {
    finalData = [
      {
        id: "search-results",
        name: "Search Results",
        slug: "search-results",
        items: filterSearchResults(searchResults),
        item_count: filterSearchResults(searchResults).length,
        sub_categories: [],
      },
    ];
  }

  return (
    <div className="explore-wrapper ">
      {renderHelmet(seoData)}
      <div className="container">
        <div className="explore-container">
          {(!bannerApiLoaded || bannersForDisplay.length > 0) && (
            <ImageCarousel
              isMobileView={isMobileView}
              data={bannersForDisplay}
              primaryColor={primaryColor}
              loading={bannersForDisplay.length === 0 && !bannerApiLoaded}
              screenWidth={screenWidth}
              widthToHeightRatio={5 / 2}
            />
          )}

          <ItemList
            {...props}
            isMobileView={isMobileView}
            loading={catalogueLoading || recommendedLoading}
            primaryColor={primaryColor}
            categoryWiseItemList={finalData}
            primaryTextColor={primaryTextColor}
            screenWidth={screenWidth}
            recommendedError={recommendedError}
            catalogueError={catalogueError}
            cart={cart}
          />
        </div>
      </div>

      {cart?.totalItemsCount > 0 && isMobileView && (
        <FloatingCart detailed={true} config={config} cart={cart} />
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    config: state.config,
    upsdk: state.upsdk,
    selectedStore: upsdkSelector.getStore(state),
    cart: upsdkSelector.getCart(state),
    items: state.catalogue.data ? state.catalogue.data.items : [],
    categories: state.catalogue.data ? state.catalogue.data.categories : [],
    catalogueLoading: state.catalogue.pending || state.search.pending,
    catalogueError: state.catalogue.error === null ? false : true,
    appliedOptions: state.catalogue.data
      ? state.catalogue.data.appliedOptions
      : {},
    searchResults: state.search.data,
    fulfillmentType: upsdkSelector.getFullfilment(state),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      likeItem: likeItem,
      unlikeItem: unlikeItem,
      fetchLikes: fetchLikes,
    },
    dispatch
  );

export default withTranslation(
  ["translations"],
  translateOptions
)(connect(mapStateToProps, mapDispatchToProps)(Explore));
