import React, { useState, lazy, Suspense } from "react";
import { connect } from "react-redux";
import { Route, Switch, Redirect } from "react-router-dom";

import Explore from "../views/explore/index.component";
import LandingScreen from "../views/landing-screen/index.component";
import BaseLayout from "../common/base-layout/index.component";
import { getAllCustomRoutes } from "common/helpers/getFooterLinks";
import configSelector from "common/store/selectors/configSelector";
import pageSelector from "common/store/selectors/pageSelector";
import upsdkSelector from "common/store/selectors/upsdkSelector";

import getCurrentFallbackHandler from "../helpers/shimmerRoutingHelper";
import CustomPage from "../views/custom-page/index.component";

const Cart = lazy(() =>
  import("../views/cart/index.component" /* webpackChunkName: "Cart" */)
);
const Orders = lazy(() =>
  import("../views/orders/index.component" /* webpackChunkName: "Orders" */)
);
const Wallet = lazy(() =>
  import("../views/wallet/index.component" /* webpackChunkName: "Wallet" */)
);
const Profile = lazy(() =>
  import("../views/profile/index.component" /* webpackChunkName: "Profile" */)
);
const Coupons = lazy(() =>
  import("../views/coupons/index.component" /* webpackChunkName: "Coupons" */)
);
const Checkout = lazy(() =>
  import("../views/checkout/index.component" /* webpackChunkName: "Checkout" */)
);
const Favorites = lazy(() =>
  import(
    "../views/favorites/index.component" /* webpackChunkName: "Favourites" */
  )
);
const ItemDetails = lazy(() =>
  import(
    "../views/item-details/index.component" /* webpackChunkName: "ItemDetails" */
  )
);
const StoreLocator = lazy(() =>
  import(
    "../views/store-locator/index.component" /* webpackChunkName: "StoreLocator" */
  )
);
const OrderSuccess = lazy(() =>
  import(
    "../views/order-success/index.component" /* webpackChunkName: "OrderSuccess" */
  )
);
const ReferAndEarn = lazy(() =>
  import(
    "../views/refer-and-earn/index.component" /* webpackChunkName: "ReferAndEarn" */
  )
);

const ByRef = lazy(() =>
  import("../views/by-ref/index.component" /* webpackChunkName: "ByRef" */)
);

const PageNotFound = lazy(() =>
  import(
    "../views/page-not-found/index.component" /* webpackChunkName: "PageNotFound" */
  )
);

const PaymentRedirect = lazy(() => import("../views/pg/index.component"));

function Routes(props) {
  const [currentFallback, setCurrentFallback] = useState(<div />);

  // props
  const { config } = props;

  // variables
  const menuPageConfig = pageSelector.getMenuPage({ config });
  const walletPageConfig = configSelector.getWalletConfig({ config });
  const itemDetailPageConfig = pageSelector.getItemDetailPage({ config });
  const orderSuccessPageConfig = pageSelector.getOrderSuccessPage({ config });
  const storeLocatorPageConfig = pageSelector.getStoreLocatorPage({ config });
  const cartPageConfig = pageSelector.getCartPage({ config });
  const checkoutPageConfig = pageSelector.getCheckoutPage({ config });
  const profilePageConfig = pageSelector.getProfilePage({ config });
  const referEarnPageConfig = pageSelector.getReferEarnPage({ config });
  const orderHistoryPageConfig = pageSelector.getOrderHistoryPage({ config });
  const favouritePageConfig = pageSelector.getFavouritesPage({ config });
  const couponsPageConfig = pageSelector.getCouponsPage({ config });
  const landingScreenConfig = pageSelector.getLandingPage({ config });
  const pageNotFoundConfig = pageSelector.getPageNotFoundPage({ config });
  const isReferralEnabled = configSelector.getReferralBool({ config });

  const allRoutes = getAllCustomRoutes(
    configSelector.getCustomPages({ config })
  );

  const privateRoutes = [
    "/past-orders",
    "/favourites",
    "/wallet",
    "/profile",
    "/coupons",
    "/refer-and-earn",
  ];

  //they are being passed to helper function via base layout
  const parameterObj = {
    getCurrentFallbackHandler,
    setCurrentFallback,
    allRoutes,
    privateRoutes,
  };

  const menuRoute = landingScreenConfig?.enabled ? "/menu/" : "/";

  // const PrivateRoute = ({ component: Component, ...rest }) => (
  //   <Route
  //     {...rest}
  //     render={(props) =>
  //       rest.userData ? <Component {...props} /> : <Redirect to={menuRoute} />
  //     }
  //   />
  // );

  return (
    <BaseLayout {...parameterObj}>
      <Suspense fallback={currentFallback}>
        <Switch>
          {landingScreenConfig?.enabled && (
            <Route exact path="/">
              <LandingScreen />
            </Route>
          )}

          {itemDetailPageConfig.enabled && (
            <Route exact path="/item-list/:id/:slug" component={ItemDetails} />
          )}

          {orderSuccessPageConfig.enabled && (
            <Route exact path="/order-success/:id" component={OrderSuccess} />
          )}

          {storeLocatorPageConfig.enabled && (
            <Route exact path="/store-locator" component={StoreLocator} />
          )}

          {allRoutes.map((page) => (
            <Route path={page.path} key={page.path} component={CustomPage} />
          ))}

          {cartPageConfig.enabled && (
            <Route exact {...props} path="/cart" component={Cart} />
          )}

          {checkoutPageConfig.enabled && (
            <Route exact {...props} path="/checkout" component={Checkout} />
          )}

          {orderHistoryPageConfig.enabled && (
            <Route exact {...props} path="/past-orders" component={Orders} />
          )}

          {favouritePageConfig.enabled && (
            <Route exact {...props} path="/favourites" component={Favorites} />
          )}

          {walletPageConfig.enable && (
            <Route exact {...props} path="/wallet" component={Wallet} />
          )}

          {profilePageConfig.enabled && (
            <Route exact {...props} path="/profile" component={Profile} />
          )}

          {couponsPageConfig.enabled && (
            <Route exact {...props} path="/coupons" component={Coupons} />
          )}

          {referEarnPageConfig.enabled && isReferralEnabled && (
            <Route
              exact
              {...props}
              path="/refer-and-earn"
              component={ReferAndEarn}
            />
          )}

          {referEarnPageConfig.enabled && isReferralEnabled && (
            <Route exact {...props} path="/by_ref" component={ByRef} />
          )}

          <Route {...props} path="/pg" component={PaymentRedirect} />

          {/* Keep menu routes in the bottom to make sure other routes are accessible and work fine */}
          {menuPageConfig.enabled && (
            <Route exact path={menuRoute} component={Explore} />
          )}

          {menuPageConfig.enabled && (
            <Route exact path={`${menuRoute}:catSlug`} component={Explore} />
          )}

          {menuPageConfig.enabled && (
            <Route
              exact
              path={`${menuRoute}:catSlug/:subCatSlug`}
              component={Explore}
            />
          )}

          <Route {...props}>
            {pageNotFoundConfig.enabled ? (
              <PageNotFound />
            ) : (
              <Redirect to={menuRoute} />
            )}
          </Route>
        </Switch>
      </Suspense>
    </BaseLayout>
  );
}

function mapStateToProps(state) {
  return {
    config: state.config,
    userData: state.auth.data,
    authToken: upsdkSelector.getAuthHeader(state),
  };
}

export default connect(mapStateToProps, null)(Routes);
