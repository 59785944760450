import React from "react";

export default function OrderNowIcon({ fill, size, className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      className={className}
    >
      <defs>
        <path
          id="ykpp5yl5ka"
          d="M15.5 14c.276 0 .5.224.5.5s-.224.5-.5.5H.5c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h15zm0-1.5c0 .552-.448 1-1 1h-13c-.552 0-1-.448-1-1h15zM8 5l.102.007c.366.05.648.363.648.743l.237.004C12.605 5.88 15.5 8.851 15.5 12.5H.5l.004-.237C.63 8.645 3.601 5.75 7.25 5.75l.007-.102C7.307 5.282 7.62 5 8 5zM6 0c.103 0 .186.083.186.186 0 .017-.002.034-.007.05C6.06.663 6 1.02 6 1.309c0 .766.25.945.25 1.711 0 .115-.054.401-.16.86l-.043.18c-.027.112-.128.191-.243.191H5.75c-.109 0-.197-.088-.197-.197 0-.015.002-.03.005-.046.128-.542.192-.87.192-.988 0-.78-.25-.93-.25-1.711 0-.24.045-.542.136-.902l.06-.224c.03-.108.13-.182.241-.182H6zm2.375 0c.103 0 .186.083.186.186 0 .017-.002.034-.007.05-.12.426-.179.783-.179 1.072 0 .766.25.945.25 1.711 0 .115-.054.401-.16.86l-.043.18c-.027.112-.128.191-.243.191h-.054c-.109 0-.197-.088-.197-.197 0-.015.002-.03.005-.046.128-.542.192-.87.192-.988 0-.78-.25-.93-.25-1.711 0-.24.045-.542.136-.902l.06-.224c.03-.108.13-.182.241-.182h.063zm2.375 0c.103 0 .186.083.186.186 0 .017-.002.034-.007.05-.12.426-.179.783-.179 1.072 0 .766.25.945.25 1.711 0 .115-.054.401-.16.86l-.043.18c-.027.112-.128.191-.243.191H10.5c-.109 0-.197-.088-.197-.197 0-.015.002-.03.005-.046.128-.542.192-.87.192-.988 0-.78-.25-.93-.25-1.711 0-.24.045-.542.136-.902l.06-.224c.03-.108.13-.182.241-.182h.063z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <g>
                <g transform="translate(-32.000000, -124.000000) translate(16.000000, 112.000000) translate(16.000000, 12.000000)">
                  <circle cx="16" cy="16" r="16" fill={fill} />
                  <g transform="translate(8.000000, 8.000000)">
                    <mask id="vpa30p2w3b" fill="#fff">
                      <use xlinkHref="#ykpp5yl5ka" />
                    </mask>
                    <use
                      fill="#503E9D"
                      fillRule="nonzero"
                      xlinkHref="#ykpp5yl5ka"
                    />
                    <path
                      fill="#000"
                      d="M-8 -8H24V24H-8z"
                      mask="url(#vpa30p2w3b)"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );

  // return (
  //   <svg
  //     xmlns="http://www.w3.org/2000/svg"
  //     xmlnsXlink="http://www.w3.org/1999/xlink"
  //     width="20"
  //     height="20"
  //     viewBox="0 0 24 24"
  //     className={className}
  //   >
  //     <defs>
  //       <path
  //         id="cjj3ckwyya"
  //         d="M21.333 2.667v2.666H16V2.667h5.333zM8 2.667v8H2.667v-8H8zm13.333 10.666v8H16v-8h5.333zM8 18.667v2.666H2.667v-2.666H8zM24 0H13.333v8H24V0zM10.667 0H0v13.333h10.667V0zM24 10.667H13.333V24H24V10.667zM10.667 16H0v8h10.667v-8z"
  //       />
  //     </defs>
  //     <g fill="none" fillRule="evenodd">
  //       <g>
  //         <g>
  //           <g>
  //             <g>
  //               <g>
  //                 <g transform="translate(-32 -128) translate(16 112) translate(16 16)">
  //                   <path d="M0 0H24V24H0z" />
  //                   <mask id="wb967y22xb" fill="#fff">
  //                     <use xlinkHref="#cjj3ckwyya" />
  //                   </mask>
  //                   <use
  //                     fill="#000"
  //                     fillRule="nonzero"
  //                     xlinkHref="#cjj3ckwyya"
  //                   />
  //                   <path
  //                     fill={fill}
  //                     d="M0 0H24V24H0z"
  //                     mask="url(#wb967y22xb)"
  //                   />
  //                 </g>
  //               </g>
  //             </g>
  //           </g>
  //         </g>
  //       </g>
  //     </g>
  //   </svg>
  // );
}
