import React, { useEffect } from "react";

import ItemCard from "../../item-card/index.component";
import SubCategoryBreadcrumb from "./SubCategoryBreadcrumb";
import Accordian from "../../accordian/index.component";
import { COLOR_WHITE } from "../../../constants/colors.constants";

function SubCategorySection({
  category,
  subCategory,
  primaryTextColor,
  secondaryTextColor,
  secondaryColor,
  primaryColor,
  selectedFontFamily,
  onSubCategoryActivation,
  subCategoryRefs,
  observerConfig,
  activeSubCategory,
  isMobileView,
}) {
  const handleSubCategoryIntersection = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting && !isMobileView) {
        // to make sure the sub category url updates
        setTimeout(() => {
          onSubCategoryActivation(category, subCategory);
        });
      }
    });
  };

  useEffect(() => {
    const subCategoryObserver = new IntersectionObserver(
      handleSubCategoryIntersection,
      observerConfig
    );
    subCategoryObserver.observe(subCategoryRefs[subCategory.id].current);

    return () => subCategoryObserver.disconnect();
    /**
     * we only need observerConfig and id, including the rest will give
     * raise to jankiness
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [observerConfig, subCategory.id]);

  const subCategoryWiseItems = (subCategory, category) => (
    <>
      {subCategory.description ? (
        <div
          dangerouslySetInnerHTML={{
            __html: `${subCategory.description}`,
          }}
          style={{
            color: secondaryTextColor,
            fontFamily: selectedFontFamily,
            fontWeight: "normal !important",
            margin: "8px 0",
          }}
        />
      ) : category.description ? (
        <div
          dangerouslySetInnerHTML={{
            __html: `${category.description}`,
          }}
          style={{
            color: secondaryTextColor,
            fontFamily: selectedFontFamily,
            fontWeight: "normal !important",
            margin: "8px 0",
          }}
        />
      ) : null}
      <div className="category-item-list">
        {subCategory.items?.map((subCategoryItem) => (
          <ItemCard
            item={subCategoryItem}
            key={subCategoryItem.id}
            secondaryColor={secondaryColor}
            primaryColor={primaryColor}
            primaryTextColor={primaryTextColor}
            secondaryTextColor={secondaryTextColor}
            selectedFontFamily={selectedFontFamily}
          />
        ))}
      </div>
    </>
  );

  return (
    <div
      id={subCategory.id}
      ref={subCategoryRefs[subCategory.id]}
      data-sub-slug={`s-${subCategory.slug}`}
      className="element"
      style={{
        backgroundColor: COLOR_WHITE,
        padding: "0px 12px",
        marginBottom: "12px",
      }}
    >
      <Accordian
        summary={
          <div className="section-sub-heading-wrapper">
            <SubCategoryBreadcrumb
              primaryTextColor={primaryTextColor}
              secondaryTextColor={secondaryTextColor}
              category={category.name}
              subCategory={subCategory.name}
              subCategoryItemCount={subCategory.item_count}
            />
          </div>
        }
        active={activeSubCategory === subCategory.id}
        details={subCategoryWiseItems(subCategory, category)}
        iconColor="#A6A6A6"
        menu={true}
      />
    </div>
  );
}

export default SubCategorySection;
