import React from "react";

export default function ReferAndEarnIcon({ fill, size, className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      className={className}
    >
      <defs>
        <path
          id="moctgjnl0a"
          d="M10.182 0c-1.607 0-2.91 1.343-2.91 3s1.303 3 2.91 3c1.606 0 2.909-1.343 2.909-3s-1.303-3-2.91-3zm-8 2.25V4.5H0V6h2.182v2.25h1.454V6h2.182V4.5H3.636V2.25H2.182zm8 4.5c-1.942 0-5.818.997-5.818 3V12H16V9.75c0-2.003-3.876-3-5.818-3z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <g>
                <g transform="translate(-32.000000, -348.000000) translate(16.000000, 112.000000) translate(16.000000, 236.000000)">
                  <circle cx="16" cy="16" r="16" fill={fill} fillOpacity="1" />
                  <g transform="translate(8.000000, 10.000000)">
                    <mask id="9zqzvwptrb" fill="#fff">
                      <use xlinkHref="#moctgjnl0a" />
                    </mask>
                    <use
                      fill="#000"
                      fillRule="nonzero"
                      xlinkHref="#moctgjnl0a"
                    />
                    <path
                      fill="#000"
                      d="M-8 -10H24V22H-8z"
                      mask="url(#9zqzvwptrb)"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
