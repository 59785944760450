import React from "react";

export default function PinterestIcon({ fill, size, className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      className={className}
    >
      <g fill="none" fillRule="evenodd">
        <g fill="#E60022" fillRule="nonzero">
          <g>
            <g>
              <path
                d="M20 10c0 5.524-4.476 10-10 10-1.032 0-2.024-.157-2.96-.448.408-.665 1.016-1.754 1.242-2.62.121-.468.621-2.38.621-2.38.327.621 1.278 1.15 2.29 1.15 3.017 0 5.19-2.775 5.19-6.222 0-3.303-2.698-5.774-6.165-5.774-4.315 0-6.61 2.895-6.61 6.052 0 1.468.783 3.294 2.03 3.875.189.089.29.048.334-.133.032-.137.201-.819.278-1.133.024-.1.012-.19-.069-.286-.407-.504-.737-1.424-.737-2.283 0-2.205 1.669-4.338 4.516-4.338 2.455 0 4.177 1.673 4.177 4.068 0 2.706-1.367 4.58-3.145 4.58-.98 0-1.718-.81-1.48-1.806.282-1.19.827-2.471.827-3.33 0-.766-.412-1.407-1.266-1.407-1.004 0-1.81 1.036-1.81 2.427 0 .887.297 1.484.297 1.484s-.987 4.185-1.169 4.968c-.201.862-.12 2.08-.036 2.87C2.637 17.86 0 14.239 0 10 0 4.476 4.476 0 10 0s10 4.476 10 10z"
                transform="translate(-1226 -472) translate(1208 275) translate(18 197)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
