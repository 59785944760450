import React, { useState, useContext, useEffect } from "react";
import { BaseContext } from "../../context/BaseContext";
import ChevronIcon from "../../assets/icons/chevron.icon";
import { COLOR_SPUN_PEARL } from "../../constants/colors.constants";
import classNames from "classnames";
import "./index.component.scss";

export default function Accordian({
  summary,
  details,
  showByDefault = true,
  iconColor,
  iconBackgroundColor,
  showOnDesktop = false,
  active,
}) {
  const [showDetails, setShowDetails] = useState(showByDefault);
  const BaseConsumer = useContext(BaseContext);

  const { isMobileView } = BaseConsumer;

  const toggleShowDetails = () => {
    if (!showOnDesktop && !isMobileView) {
      return;
    }
    setShowDetails((status) => !status);
  };

  useEffect(() => {
    if (active) {
      setShowDetails(true);
    }
  }, [active]);

  return (
    <div className="accordian-wrapper">
      <div onClick={toggleShowDetails} className="accordian-summary">
        {summary}
        {!showOnDesktop && !isMobileView ? null : (
          <ChevronIcon
            fill={iconColor || COLOR_SPUN_PEARL}
            className={classNames("accordian-chevron", {
              "chevron-down": !showDetails,
              "chevron-up": showDetails,
            })}
            size={{ width: 12, height: 12 }}
            backgroundColor={iconBackgroundColor}
          />
        )}
      </div>
      {showDetails && <div className="accordian-details">{details}</div>}
    </div>
  );
}
