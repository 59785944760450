import React from "react";

export default function TwitterBirdIcon({ fill, size, className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="18"
      viewBox="0 0 22 18"
      className={className}
    >
      <g fill="none" fillRule="evenodd">
        <g fill="#55ACEE" fillRule="nonzero">
          <g>
            <g>
              <path
                d="M22 2.058c-.78.355-1.632.568-2.484.71.923-.568 1.561-1.42 1.916-2.413-.851.497-1.774.851-2.767 1.064C17.813.497 16.677 0 15.47 0c-2.413 0-4.4 1.987-4.4 4.4 0 .355.07.71.142.994C7.593 5.18 4.329 3.477 2.2.78c-.355.638-.568 1.419-.568 2.2 0 1.49.78 2.838 1.916 3.619-.71 0-1.419-.213-1.987-.497v.071c0 2.13 1.49 3.903 3.478 4.258-.355.071-.78.142-1.136.142-.284 0-.568 0-.851-.07.567 1.702 2.2 2.98 4.045 3.05C5.607 14.762 3.69 15.4 1.703 15.4c-.355 0-.71 0-1.064-.071 1.916 1.206 4.258 1.987 6.67 1.987 8.02 0 12.42-6.67 12.42-12.42V4.33c.923-.639 1.703-1.42 2.271-2.27z"
                transform="translate(-1225 -360) translate(1208 275) translate(17 85)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
