import React from "react";
import classNames from "classnames";
import { CircularProgress } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import itemSelector from "common/store/selectors/itemSelector";
import FavouriteContainedIcon from "../../assets/icons/favorite-contained.icon";
import FavouriteOutlinedIcon from "../../assets/icons/favorite-outlined.icon";
import Typography from "../typography/index.component";

import "./index.component.scss";

export default function FavoriteIndicator({
  likes,
  isFavorite,
  isLoading,
  primaryColor,
  config,
  markFavoriteCallback,
  markUnfavoriteCallback,
}) {
  // variables
  const showLikesCount = itemSelector.showLikesCount({ config });
  const likeCount = isFavorite ? likes + 1 : likes;
  const { i18n } = useTranslation();
  const direction = i18n.dir();

  const handleFavoriteClick = (event) => {
    markFavoriteCallback();
    event.stopPropagation();
  };

  const handleUnFavoriteClick = (event) => {
    markUnfavoriteCallback();
    event.stopPropagation();
  };

  const Loader = () => (
    <CircularProgress
      classes={{ root: "button-loader" }}
      style={{ width: 18, height: 18 }}
    />
  );

  return (
    <div
      className={classNames("favorite-indicator-wrapper", {
        "icon-position-ltr": direction === "ltr",
        "icon-position-rtl": direction === "rtl",
      })}
    >
      {isLoading ? (
        <div className="icon-wrapper" onClick={handleUnFavoriteClick}>
          <Loader />
        </div>
      ) : isFavorite ? (
        <div className="icon-wrapper" onClick={handleUnFavoriteClick}>
          <FavouriteContainedIcon
            fill={primaryColor}
            className="favorite-icon"
          />
          {showLikesCount && (
            <Typography
              variant="h4"
              weight="regular"
              className="likes-count"
              fontColor={primaryColor}
            >
              {likeCount}
            </Typography>
          )}
        </div>
      ) : (
        <div className="icon-wrapper" onClick={handleFavoriteClick}>
          <FavouriteOutlinedIcon
            fill={primaryColor}
            className="favorite-icon"
          />
          {likes ? (
            <Typography
              variant="h4"
              weight="regular"
              className="likes-count"
              fontColor={primaryColor}
            >
              {likes}
            </Typography>
          ) : null}
        </div>
      )}
    </div>
  );
}
