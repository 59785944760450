import { useLocation, useHistory } from "react-router-dom";

export default function useRouteHook() {
  const { search, pathname } = useLocation();
  const history = useHistory();

  const historyPush = (to) => {
    history.push(to + "/" + search);
  };

  const historyGoBack = () => {
    history.goBack();
  };

  return {
    historyPush,
    historyGoBack,
    pathname,
  };
}
