import React from "react";
export default function BookmarkIcon({ fill, size, className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      className={className}
    >
      <defs>
        <path
          id="a7ng0indaa"
          d="M8.901 12l-4.45-2.012L0 12V1c0-.552.448-1 1-1h6.901c.552 0 1 .448 1 1v11z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <g>
                <g transform="translate(-32.000000, -236.000000) translate(16.000000, 112.000000) translate(16.000000, 124.000000)">
                  <circle cx="16" cy="16" r="16" fill={fill} fillOpacity="1" />
                  <g transform="translate(12.000000, 10.000000)">
                    <use
                      fill="#000"
                      fillRule="nonzero"
                      xlinkHref="#a7ng0indaa"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
