export const SET_SUB_LOCALITY = "SET_SUB_LOCALITY";
export const CLEAR_SUB_LOCALITY = "CLEAR_SUB_LOCALITY";

export const SET_LOCATION_DIALOG = "SET_LOCATION_DIALOG";
export const UNSET_LOCATION_DIALOG = "UNSET_LOCATION_DIALOG";

export const SET_SELECTED_ADDRESS = "SET_SELECTED_ADDRESS";
export const CLEAR_SELECTED_ADDRESS = "CLEAR_SELECTED_ADDRESS";

export const setSubLocality = (data) => ({
  type: SET_SUB_LOCALITY,
  data: data,
});
export const clearSubLocality = (data) => ({
  type: CLEAR_SUB_LOCALITY,
  data: data,
});

export const setLocationDialog = () => ({
  type: SET_LOCATION_DIALOG,
});
export const unSetLocationDialog = () => ({
  type: UNSET_LOCATION_DIALOG,
});

export const setSelectedAddress = (data) => ({
  type: SET_SELECTED_ADDRESS,
  data: data,
});
export const clearSelectedAddress = () => ({
  type: CLEAR_SELECTED_ADDRESS,
});
