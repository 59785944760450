import React, { useRef, useState, useEffect } from "react";
import { withTranslation, useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { withRouter } from "react-router-dom";

import useSize from "@react-hook/size";
import WebFont from "webfontloader";

import { bindActionCreators } from "redux";
import getFilterTitleArr from "common/helpers/filters";
import ResponsiveHelper from "common/helpers/responsive.helper";
import analyticsPubSub, {
  ANALYTICS_EVENTS,
} from "common/services/analyticsPubSub";
import configSelector from "common/store/selectors/configSelector";
import graphqlService from "common/services/graphql/graphqlService";

import { BaseProvider } from "../../context/BaseContext";
import { DialogProvider } from "../../context/DialogContext";
import ScrollToTop from "../../hooks/scrollToTop.hook";
import { translateOptions } from "../../i18n";
import Footer from "../footer/index.component";
import Header from "../header/index.component";
import ItemCustomization from "../item-customization/index.component";
import i18next from "i18next";
import { clearSearch, fetchSearch } from "common/store/actions/search";
import fetchCatalogue from "common/store/actions/catalogue";
import "dayjs/locale/es";

import "./index.component.scss";

function QRCodeBaseLayout(props) {
  const isQrModeEnabled = true;
  const componentRef = useRef();
  const location = useLocation();
  const [width] = useSize(componentRef);

  const [qrModeBizInfo, setQrModeBizInfo] = useState(null);
  const [sortBy, setSortBy] = useState(null);
  const [filterBy, setFilterBy] = useState([]);
  const [isMobileView, setIsMobileView] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [openItemCustomization, setItemCustomization] = useState(false);
  const [searchActive, setSearchActive] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [sticky, setSticky] = useState({ isSticky: false, offset: 0 });
  const history = useHistory();
  const activeLanguage = i18next.language;
  const { i18n } = useTranslation();
  document.body.dir = i18n.dir();

  // callbacks
  const { allRoutes, fetchCatalogue } = props;

  // props
  const { cart, config, userData, options } = props;

  const primaryColor = configSelector.getPrimaryColor({ config });
  const secondaryColor = configSelector.getSecondaryColor({ config });
  const primaryTextColor = configSelector.getPrimaryTextColor({ config });
  const secondaryTextColor = configSelector.getSecondaryTextColor({ config });

  const selectedFontFamily = configSelector.getFontFamily({ config });

  useEffect(() => {
    WebFont.load({
      google: {
        families: [
          `${selectedFontFamily}:400`,
          `${selectedFontFamily}:600`,
          `${selectedFontFamily}:700`,
          "Open Sans:400",
          "Open Sans:600",
          "Open Sans:700",
        ],
      },
    });

    // TODO: Need to fix
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchCatalogue(sortBy, filterBy);
  }, [sortBy, filterBy, activeLanguage, fetchCatalogue]);

  useEffect(() => {
    graphqlService
      .getQrModeBizInfo()
      .then((data) => setQrModeBizInfo(data))
      .catch(() => {});
  }, []);

  const getQrModeStoreName = () => {
    let address = "";
    if (qrModeBizInfo && qrModeBizInfo.name) {
      address = qrModeBizInfo.name;
    }
    return address;
  };

  const sortByOnChange = (data) => {
    const eventObj = {
      sort: data,
    };
    setSortBy(data);
    analyticsPubSub.publish(ANALYTICS_EVENTS.SORT_APPLIED, eventObj);
  };

  const filterByOnChange = (data) => {
    const filterValues = getFilterTitleArr(data, options.filters);
    const eventObj = {
      filter_tags: filterValues.join(),
    };
    setFilterBy(data);
    analyticsPubSub.publish(ANALYTICS_EVENTS.FILTER_APPLIED, eventObj);
  };

  const toggleItemCustomization = (item) => {
    setItemCustomization(!openItemCustomization);
  };

  const showItemCustomization = (item) => {
    setItemCustomization(true);
    setSelectedItem(item);
  };

  const hideItemCustomization = () => {
    setItemCustomization(false);
    setSelectedItem(null);
  };

  useEffect(() => {
    if (width) {
      setIsMobileView(ResponsiveHelper.isMobile(width));
    }
  }, [width]);

  return (
    <div className="base-layout-container" ref={componentRef}>
      <BaseProvider
        value={{
          isQrModeEnabled: isQrModeEnabled,
          filterBy: filterBy,
          sortBy: sortBy,
          options: options,
          isMobileView: isMobileView,
          screenWidth: width,
          sticky: sticky,
          setSticky: setSticky,
          secondaryTextColor: secondaryTextColor,
          searchActive: searchActive,
          showItemCustomization: showItemCustomization,
          hideItemCustomization: hideItemCustomization,
          menuRoute: "",
          handleRemoveFilters: (filters) => filterByOnChange(filters),
        }}
      >
        <DialogProvider>
          <Header
            {...props}
            cart={cart}
            sortBy={sortBy}
            filterBy={filterBy}
            userData={userData}
            isMobileView={isMobileView}
            primaryColor={primaryColor}
            secondaryColor={secondaryColor}
            primaryTextColor={primaryTextColor}
            secondaryTextColor={secondaryTextColor}
            customRoutes={allRoutes}
            location={location}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            sortByCallback={sortByOnChange}
            filterByCallback={filterByOnChange}
            setSearchActive={setSearchActive}
            getQrModeStoreName={getQrModeStoreName}
          />

          <ScrollToTop customRoutes={allRoutes}>
            <div className="view-wrapper">{props.children}</div>
          </ScrollToTop>

          <Footer
            primaryColor={primaryColor}
            secondaryTextColor={secondaryTextColor}
            secondaryColor={secondaryColor}
            history={history}
            config={config}
            primaryTextColor={primaryTextColor}
          />

          <ItemCustomization
            {...props}
            open={openItemCustomization}
            toggleItemCustomizationCallback={toggleItemCustomization}
            item={selectedItem}
          />
        </DialogProvider>
      </BaseProvider>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    config: state.config,
    upsdk: state.upsdk,
  };
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchSearch: fetchSearch,
      clearSearch: clearSearch,
      fetchCatalogue: fetchCatalogue,
    },
    dispatch
  );

export default withRouter(
  withTranslation(
    ["translations"],
    translateOptions
  )(connect(mapStateToProps, mapDispatchToProps)(QRCodeBaseLayout))
);
