import React from "react";

export default function UncheckedCheckboxIcon({
  fill = "#000",
  size,
  className,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className={className}
    >
      <defs>
        <path
          id="fehtuntz0a"
          d="M21.333 0H2.667C1.187 0 0 1.187 0 2.667v18.666C0 22.806 1.194 24 2.667 24h18.666C22.806 24 24 22.806 24 21.333V2.667C24 1.187 22.8 0 21.333 0zm0 2.667v18.666H2.667V2.667h18.666z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <g>
                <g>
                  <g>
                    <g>
                      <g transform="translate(-1144 -806) translate(32 586) translate(0 67) translate(1096 41) translate(16 16) translate(0 96)">
                        <path d="M0 0H24V24H0z" />
                        <mask id="tzd7r84jtb" fill="#fff">
                          <use xlinkHref="#fehtuntz0a" />
                        </mask>
                        <use
                          fill={fill}
                          fillRule="nonzero"
                          xlinkHref="#fehtuntz0a"
                        />
                        <path
                          fill={fill}
                          d="M0 0H24V24H0z"
                          mask="url(#tzd7r84jtb)"
                        />
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
