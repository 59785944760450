import React from "react";
export default function CheckedCheckboxIcon({ fill, size, className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className={className}
    >
      <defs>
        <path
          id="zmipzoa9ha"
          d="M9.333 18.667L2.667 12l1.88-1.893 4.786 4.786 10.12-10.12 1.88 1.894-12 12zM21.333 0H2.667C1.187 0 0 1.187 0 2.667v18.666C0 22.806 1.194 24 2.667 24h18.666C22.806 24 24 22.806 24 21.333V2.667C24 1.187 22.8 0 21.333 0z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <g>
                <g>
                  <g>
                    <g>
                      <g transform="translate(-1144 -758) translate(32 586) translate(0 67) translate(1096 41) translate(16 16) translate(0 47) translate(0 1)">
                        <path d="M0 0H24V24H0z" />
                        <mask id="g1z84qx5hb" fill="#fff">
                          <use xlinkHref="#zmipzoa9ha" />
                        </mask>
                        <use
                          fill="#000"
                          fillRule="nonzero"
                          xlinkHref="#zmipzoa9ha"
                        />
                        <path
                          fill="#000"
                          d="M0 0H24V24H0z"
                          mask="url(#g1z84qx5hb)"
                        />
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
