import React, { useCallback, useState } from "react";

import ConfirmDialog from "../common/confirm-dialog/index.component";
const DialogContext = React.createContext();

const DialogProvider = (props) => {
  const [visible, setVisible] = useState();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [buttonText, setButtonText] = useState("");
  const [buttonColor, setButtonColor] = useState(null);
  const [callback, setCallback] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [toggleCallback, setToggleCallback] = useState(null);

  const unSetModal = useCallback(() => {
    setVisible();
  }, [setVisible]);

  const toggle = () => {
    setVisible(!visible);
    if (toggleCallback) {
      toggleCallback();
    }
  };

  const showDialog = ({
    title,
    description,
    buttonText,
    buttonColor,
    onClickCallback,
    onToggleCallback,
  }) => {
    setVisible(true);
    setTitle(title);
    setDescription(description);
    setButtonText(buttonText);
    setButtonColor(buttonColor);
    setCallback(() => onClickCallback);
    setToggleCallback(() => onToggleCallback);
  };

  const hideDialog = () => {
    setVisible(false);
    setIsLoading(false);
  };

  const handleButtonClick = () => {
    if (callback) {
      callback();
    }
  };

  const setDialogLoading = (value) => {
    setIsLoading(value);
  };

  return (
    <DialogContext.Provider
      value={{ unSetModal, showDialog, hideDialog, setDialogLoading }}
      {...props}
    >
      {props.children}
      {visible && (
        <ConfirmDialog
          {...props}
          isLoading={isLoading}
          open={visible}
          title={title}
          description={description}
          buttonText={buttonText}
          buttonColor={buttonColor}
          toggleConfirmDialogCallback={toggle}
          buttonClickCallback={handleButtonClick}
          unSetModal={unSetModal}
        />
      )}
    </DialogContext.Provider>
  );
};

const useDialog = () => {
  const context = React.useContext(DialogContext);
  if (context === undefined) {
    throw new Error("useModal must be used within a UserProvider");
  }

  return context;
};

export { DialogProvider, useDialog };
