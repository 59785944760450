export default function (share_link, text, type) {
  switch (type) {
    case "facebook":
      return `https://www.facebook.com/sharer/sharer.php?u=${share_link}&quote=${text}`;
    case "whatsapp":
      return `https://wa.me/?text=${encodeURIComponent(
        text + " - " + share_link
      )}`;
    case "pinterest":
      return `http://pinterest.com/pin/create/link/?url=${text}`;
    case "twitter":
      return `http://twitter.com/share?text=${text}`;
    case "messenger":
      return `https://www.facebook.com/sharer/sharer.php?u=${share_link}&quote=${text}`;
    case "email":
      return `mailto:?subject=&body=${encodeURI(text + "\n" + share_link)}`;
    default:
  }
}
