import React from "react";

export default function SettingsIcon({ fill, size, className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      className={className}
    >
      <defs>
        <path
          id="mmjcpwzdfa"
          d="M9.645 0c.205 0 .378.144.41.336l.305 2.12c.518.2.962.472 1.39.784l2.047-.8c.18-.072.403 0 .501.176l1.645 2.768c.107.176.058.392-.099.512L14.11 7.2l.058.8-.058.8 1.735 1.304c.157.12.206.336.099.512l-1.645 2.768c-.098.176-.32.248-.501.176l-2.048-.8c-.427.312-.871.584-1.39.784l-.303 2.12c-.033.192-.206.336-.411.336h-3.29c-.205 0-.378-.144-.41-.336l-.305-2.12c-.518-.2-.962-.472-1.39-.792l-2.047.808c-.18.064-.403 0-.501-.176L.057 10.616c-.107-.176-.058-.392.099-.512L1.89 8.776 1.833 8l.058-.8L.156 5.896c-.157-.12-.206-.336-.099-.512l1.645-2.768c.098-.176.32-.248.501-.176l2.048.8c.427-.312.871-.584 1.39-.784l.303-2.12C5.977.144 6.15 0 6.355 0h3.29zm-1.64 5.9c-1.185 0-2.145.934-2.145 2.086 0 1.153.96 2.087 2.145 2.087 1.184 0 2.144-.934 2.144-2.087 0-1.152-.96-2.086-2.144-2.086z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <g>
                <g transform="translate(-32.000000, -404.000000) translate(16.000000, 112.000000) translate(16.000000, 292.000000)">
                  <circle cx="16" cy="16" r="16" fill={fill} fillOpacity="1" />
                  <g transform="translate(8.000000, 8.000000)">
                    <mask id="turovrh9mb" fill="#fff">
                      <use xlinkHref="#mmjcpwzdfa" />
                    </mask>
                    <use
                      fill="#000"
                      fillRule="nonzero"
                      xlinkHref="#mmjcpwzdfa"
                    />
                    <path
                      fill="#000"
                      fillOpacity=".16"
                      d="M-8 -8H24V24H-8z"
                      mask="url(#turovrh9mb)"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
