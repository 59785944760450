import React from "react";
export default function FavouriteOutlinedIcon({ fill, size, className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      className={className}
    >
      <defs>
        <path
          id="rn93bx1y7a"
          d="M7.974 13.711l-.08.082-.086-.082c-3.75-3.523-6.23-5.853-6.23-8.215 0-1.635 1.185-2.861 2.764-2.861 1.216 0 2.4.817 2.819 1.929h1.468c.418-1.112 1.603-1.93 2.818-1.93 1.58 0 2.764 1.227 2.764 2.862 0 2.362-2.48 4.692-6.237 8.215zM11.447 1c-1.373 0-2.692.662-3.552 1.7C7.035 1.662 5.715 1 4.342 1 1.911 1 0 2.97 0 5.496c0 3.082 2.684 5.608 6.75 9.425L7.895 16l1.144-1.079c4.066-3.817 6.75-6.343 6.75-9.425C15.79 2.97 13.88 1 11.447 1z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <g>
                <g>
                  <g>
                    <g transform="translate(-318 -746) translate(32 586) translate(0 136) translate(278 16) translate(8 8)">
                      <path d="M0 0H16V16H0z" />
                      <mask id="a8i5290ivb" fill="#fff">
                        <use xlinkHref="#rn93bx1y7a" />
                      </mask>
                      <use
                        fill="#000"
                        fillRule="nonzero"
                        xlinkHref="#rn93bx1y7a"
                      />
                      <path
                        fill={fill}
                        d="M0 0H16V16H0z"
                        mask="url(#a8i5290ivb)"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
