import DataTransformer from "../../helpers/dataTransformer";

const defaultItem = {
  id: "",
  category: null,
  subCategory: null,
  name: null,
  description: null,
  price: null,
  markup_price: null,
  price_text: null,
  thumbnail: null,
  type: null,
  option_groups: null,
  extras: null,
  raw_data: null,
  current_stock: null,
  is_stock_available: null,
  isRecommended: false,
  isUpsold: false,
  from_search: false,
  from_detail_scr: false,
  from_checkout: false,
  sort_order: 0,
  likes: 0,
  currency: null,
  diet_type: null,
  image_blur_hash: null,
};

export default class Item {
  constructor(
    item = defaultItem,
    isRecommended = false,
    isUpsold = false,
    from_search = false,
    from_detail_scr = false,
    from_checkout = false
  ) {
    this.id = item.id;
    this.category = item.category;
    this.subCategory = item.sub_category ? item.sub_category : null;

    this.name = item.item_title;
    this.description = item.item_desc;
    this.price = item.item_price;
    this.sort_order = item.sort_order || 0;
    // this.markup_price_text = this.getMarkupPriceText(item);
    // this.price_text = this.getPriceText(item);
    this.thumbnail = `${item.image_url}?width=300`;
    this.image = item.image_url;
    this.type = item.food_type;
    this.option_groups = item.option_groups;
    this.extras =
      item.extras && item.extras.length
        ? item?.extras?.filter((row) => row?.name !== "sort")
        : [];
    this.has_options = item.has_options;
    this.current_stock = item.current_stock;
    this.is_stock_available =
      item.current_stock === -1 || item.current_stock > 0;
    this.isRecommended = isRecommended;
    this.isUpsold = isUpsold;
    this.from_search = from_search;
    this.from_detail_scr = from_detail_scr;
    this.from_checkout = from_checkout;
    this.likes = item.likes;
    this.currency = "₹";
    this.diet_type = DataTransformer.getDietType(item.food_type);
    this.image_blur_hash = item.image_blur_hash;

    this.raw_data = {
      ...item,
      isRecommended: isRecommended,
      isUpsold: isUpsold,
      from_search: from_search,
      from_detail_scr: from_detail_scr,
      from_checkout: from_checkout,
    };
  }

  // getMarkupPriceText(item) {
  //   if (item.item_price === 0) {
  //     return null;
  //   }
  //   let text =
  //     ConfigService.get('lbl_currency_symbol', '₹', 'string') +
  //     ' ' +
  //     item.markup_price;
  //   if (!item.markup_price) {
  //     return null;
  //   }
  //   return text;
  // }
  // getPriceText(item) {
  //   if (item.item_price === 0) {
  //     return null;
  //   }
  //   let text =
  //     ConfigService.get('lbl_currency_symbol', '₹', 'string') +
  //     ' ' +
  //     item.item_price;
  //   if (item.price_descriptor) {
  //     text = text + ' ' + item.price_descriptor;
  //   }
  //   return text;
  // }
}
