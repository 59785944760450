import React, { Fragment } from "react";
import Typography from "../../typography/index.component";

const MobileCategoryNavigator = ({
  category,
  getCategoryItemCount,
  activeMainCategory,
  activeSubCategory,
  handleScrollToActiveSection,
  primaryColor,
  setActiveMainCategory,
  setActiveSubCategory,
  handleClose,
  onSubCategoryActivation,
}) => {
  if (!category.slug) return null;
  const categoryItemsCount = getCategoryItemCount(category);

  const handleItemClick = (setterFunc, id, category) => {
    handleClose();
    if (category) {
      setActiveMainCategory(category);
    }
    setterFunc(id);
    handleScrollToActiveSection(id);
  };
  return (
    <Fragment key={category.id}>
      <li
        className="category"
        onClick={() => handleItemClick(setActiveMainCategory, category.id)}
      >
        <div
          className={`category-link ${
            activeMainCategory === category.id ? "active" : ""
          }`}
        >
          {activeMainCategory === category.id && (
            <span
              className="active-category-dot"
              style={{ backgroundColor: primaryColor }}
            ></span>
          )}
          <Typography variant="h2" weight="semiBold" className="category-name">
            {category.name}
          </Typography>
          <Typography
            variant="h3"
            weight="regular"
            className="category-item-count"
          >
            ({categoryItemsCount})
          </Typography>
        </div>
      </li>
      {category.sub_categories && category.sub_categories.length ? (
        <MobileSubCategoryNavigator
          subCategories={category.sub_categories}
          activeSubCategory={activeSubCategory}
          activeMainCategory={activeMainCategory}
          handleScrollToActiveSection={handleScrollToActiveSection}
          setActiveSubCategory={setActiveSubCategory}
          handleItemClick={handleItemClick}
          category={category.id}
        />
      ) : null}
    </Fragment>
  );
};

const MobileSubCategoryNavigator = ({
  subCategories,
  index,
  activeSubCategory,
  setActiveSubCategory,
  handleItemClick,
  category,
}) => (
  <ul key={index} className="sub-category-list">
    {subCategories.map(({ id, name }) => (
      <li
        className="category"
        key={id}
        onClick={() => handleItemClick(setActiveSubCategory, id, category)}
      >
        <div
          className={`category-link ${
            activeSubCategory === id ? "sub-category-active" : ""
          }`}
        >
          <Typography
            variant="h3"
            weight="regular"
            className="mobile-view-sub-category-text"
          >
            {name}
          </Typography>
        </div>
      </li>
    ))}
  </ul>
);

export default MobileCategoryNavigator;
