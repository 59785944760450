import React from "react";

import { COLOR_SPUN_PEARL } from "../../../constants/colors.constants";
import ChevronIcon from "../../../assets/icons/chevron.icon";
import Typography from "../../typography/index.component";

const SubCategoryBreadcrumb = ({
  category,
  subCategory,
  subCategoryItemCount,
  secondaryTextColor,
  primaryTextColor,
}) => (
  <div className="sub-heading-container">
    <Typography
      variant="h1"
      weight="bold"
      className="sub-heading"
      fontColor={primaryTextColor}
    >
      {category}
    </Typography>
    <ChevronIcon
      fill={COLOR_SPUN_PEARL}
      className="breadcrumb-chevron"
      size={{ width: 5, height: 5 }}
    />
    <Typography
      variant="h1"
      weight="bold"
      className="sub-heading"
      fontColor={secondaryTextColor}
    >
      {`${subCategory} (${subCategoryItemCount})`}
    </Typography>
  </div>
);

export default SubCategoryBreadcrumb;
