import React from "react";
export default function KeyIcon({ fill, size, className, onClickCallback }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className={className}
    >
      <defs>
        <path
          id="sokm8h362a"
          d="M24 19.2V24h-4.8v-3.6h-3.6v-3.6H12l-2.712-2.712c-.66.204-1.356.312-2.088.312-3.976 0-7.2-3.224-7.2-7.2S3.224 0 7.2 0s7.2 3.224 7.2 7.2c0 .732-.108 1.428-.312 2.088L24 19.2zM6 3.6C4.675 3.6 3.6 4.675 3.6 6S4.675 8.4 6 8.4 8.4 7.325 8.4 6 7.325 3.6 6 3.6z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <g>
                <g>
                  <g>
                    <g>
                      <g transform="translate(-484 -516) translate(400 168) translate(32 88) translate(32 144) translate(0 96) translate(20 20)">
                        <path d="M0 0H24V24H0z" />
                        <mask id="0z92qxqo8b" fill="#fff">
                          <use xlinkHref="#sokm8h362a" />
                        </mask>
                        <use
                          fill="#000"
                          fillRule="nonzero"
                          xlinkHref="#sokm8h362a"
                        />
                        <path
                          fill="#000"
                          d="M0 0H24V24H0z"
                          mask="url(#0z92qxqo8b)"
                        />
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
