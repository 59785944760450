import React from "react";

export default function WalletMenuIcon({ fill, size, className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      className={className}
    >
      <defs>
        <path
          id="2six6rw56a"
          d="M1.684 0C.75 0 0 .733 0 1.63v11.407c0 .9.754 1.63 1.684 1.63h11.79c.93 0 1.684-.73 1.684-1.63v-1.858c.497-.285.842-.798.842-1.401v-4.89c0-.602-.345-1.115-.842-1.4V1.63c0-.9-.754-1.63-1.684-1.63H1.684zm6.737 4.889h5.895v4.889H8.42v-4.89zm2.526 1.222c-.697 0-1.263.547-1.263 1.222 0 .675.566 1.223 1.263 1.223.698 0 1.264-.548 1.264-1.223s-.566-1.222-1.264-1.222z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <g>
                <g transform="translate(-32.000000, -292.000000) translate(16.000000, 112.000000) translate(16.000000, 180.000000)">
                  <circle cx="16" cy="16" r="16" fill={fill} fillOpacity="1" />
                  <g transform="translate(8.000000, 9.000000)">
                    <mask id="x3c08f2ehb" fill="#fff">
                      <use xlinkHref="#2six6rw56a" />
                    </mask>
                    <use
                      fill="#000"
                      fillRule="nonzero"
                      xlinkHref="#2six6rw56a"
                    />
                    <path
                      fill="#000"
                      d="M-8 -9H24V23H-8z"
                      mask="url(#x3c08f2ehb)"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
