import React, { memo, useEffect, useState, useContext } from "react";
import upsdkService from "common/services/upsdkService";
import fetchCatalogue from "common/store/actions/catalogue";
import RecommendedItem from "./recommended-item/index.component";
import upsdkSelector from "common/store/selectors/upsdkSelector";
import configSelector from "common/store/selectors/configSelector";
import Typography from "../../../common/typography/index.component";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { translateOptions } from "../../../i18n";
import { ZIGZAG_SEPARATOR } from "../../../constants/image.constants";

import "./index.component.scss";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import "swiper/swiper.min.css";

import "swiper/modules/pagination/pagination.min.css";
import "swiper/modules/navigation/navigation.min.css";
import { Navigation, Pagination } from "swiper";
import { BaseContext } from "../../../context/BaseContext";

function RecommendedSection(props) {
  const [recommended, setRecommended] = useState([]);
  const [loading, setLoading] = useState(true);

  // props
  const { config, categories, selectedStore, fulfillmentType } = props;
  const BaseConsumer = useContext(BaseContext);
  const { isMobileView } = BaseConsumer;

  // callbacks
  const { t, fetchCatalogue } = props;

  // varialbes
  const location_id = selectedStore ? selectedStore.id : null;
  const secondaryColor = configSelector.getSecondaryColor({ config });
  const primaryTextColor = configSelector.getPrimaryTextColor({ config });

  useEffect(() => {
    fetchCatalogue();
    // eslint-disable-next-line
  }, [location_id]);

  useEffect(() => {
    setLoading(true);
    upsdkService
      .getRecommendedItems([0], location_id)
      .then((response) => {
        if (response && response.length > 0) {
          setRecommended(response);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [location_id, categories, fulfillmentType]);

  if (!loading && !recommended.length) {
    return null;
  }

  return (
    <div dir="ltr" className="recommended-section-wrapper">
      <div className="recommended-items-container container">
        <Typography
          variant="h1"
          weight="semiBold"
          className="section-title"
          fontColor={secondaryColor}
        >
          {t("common.recommendationItems")}
        </Typography>

        <img src={ZIGZAG_SEPARATOR} alt="separator" className="separator" />

        <div className="recommended-item-list ">
          <Swiper
            slidesPerView={isMobileView ? 1 : 3}
            spaceBetween={30}
            slidesPerGroup={3}
            loop={true}
            loopFillGroupWithBlank={false}
            pagination={false}
            navigation={true}
            modules={[Pagination, Navigation]}
            className="mySwiper"
          >
            {recommended.map((item, index) => (
              <SwiperSlide key={index}>
                <RecommendedItem
                  {...props}
                  primaryTextColor={primaryTextColor}
                  item={item}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    config: state.config,
    selectedStore: upsdkSelector.getStore(state),
    categories: state.catalogue.data ? state.catalogue.data.categories : [],
    fulfillmentType: upsdkSelector.getFullfilment(state),
  };
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchCatalogue: fetchCatalogue,
    },
    dispatch
  );

export default withRouter(
  withTranslation(
    ["translations"],
    translateOptions
  )(connect(mapStateToProps, mapDispatchToProps)(memo(RecommendedSection)))
);
