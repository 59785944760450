import React from "react";
import Typography from "../typography/index.component";
import FloatingCta from "../floating-cta/index.component";
import CurrencyHelper from "common/helpers/currency.helper";
import { COLOR_WHITE } from "../../constants/colors.constants";
import useRouteHook from "../../hooks/useRoute.hook";
import { useTranslation } from "react-i18next";

import "./index.component.scss";

export default function FloatingCart(props) {
  const { config, detailed, cart } = props;
  const { total, totalItemsCount } = cart;
  const { historyPush } = useRouteHook();
  const { t } = useTranslation();

  return (
    <FloatingCta
      config={config}
      onClickCallback={() => {
        historyPush("/cart");
      }}
    >
      <div className="cart-button">
        <div className="details-wrapper">
          <Typography variant="h3" weight="regular" fontColor={COLOR_WHITE}>
            {totalItemsCount > 1
              ? t("buttons.itemCountPlural", {
                  itemCount: totalItemsCount,
                })
              : t("buttons.itemCountSingular", {
                  itemCount: totalItemsCount,
                })}
          </Typography>
          {detailed && (
            <Typography
              variant="h2"
              weight="regular"
              fontColor={COLOR_WHITE}
              className="price-detail"
            >
              {CurrencyHelper.format(total)}
            </Typography>
          )}
        </div>
        <Typography variant="h2" weight="bold" fontColor={COLOR_WHITE}>
          {t("header.viewCart")}
        </Typography>
      </div>
    </FloatingCta>
  );
}
