import { Tooltip } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

function CustomTooltip({ backgroundColor, color, children, title, ...props }) {
  const CustomizedTooltip = withStyles({
    tooltip: {
      color: color,
      backgroundColor: backgroundColor,
      boxShadow: "2px 2px 4px rgb(171, 167, 167)",
    },
    arrow: {
      "&:before": {
        color: backgroundColor,
        boxShadow: "1px 1px 2px rgb(171, 167, 167)",
      },
    },
  })(Tooltip);
  return (
    <CustomizedTooltip title={title ? title : "custom-tooltip"} {...props}>
      {children}
    </CustomizedTooltip>
  );
}

export default CustomTooltip;
