import upsdkService from "../../services/upsdkService";

export const FETCH_LIKES_PENDING = "FETCH_LIKES_PENDING";
export const FETCH_LIKES_ERROR = "FETCH_LIKES_ERROR";
export const FETCH_LIKES_SUCCESS = "FETCH_LIKES_SUCCESS";

function fetchLikesPending() {
  return {
    type: FETCH_LIKES_PENDING,
  };
}

function fetchLikesSuccess(catalogue) {
  return {
    type: FETCH_LIKES_SUCCESS,
    payload: catalogue,
  };
}

function fetchLikesError(error) {
  return {
    type: FETCH_LIKES_ERROR,
    error: error,
  };
}

export function fetchLikes() {
  return (dispatch) => {
    dispatch(fetchLikesPending());
    upsdkService
      .getUserLikes()
      .then((response) => {
        dispatch(fetchLikesSuccess(response.data.likes));
      })
      .catch((error) => {
        dispatch(fetchLikesError(error));
      });
  };
}

export function likeItem(item) {
  return (dispatch) => {
    upsdkService.likeItem(item).then(() => {
      dispatch(fetchLikes());
    });
  };
}

export function unlikeItem(item) {
  return (dispatch) => {
    upsdkService.unlikeItem(item).then(() => {
      dispatch(fetchLikes());
    });
  };
}

export function clearLikes() {
  return (dispatch) => {
    dispatch(fetchLikesSuccess(null));
  };
}
