import React, { useEffect, createRef } from "react";
import { useTranslation } from "react-i18next";
import Typography from "../../typography/index.component";
import ItemCard from "../../item-card/index.component";
import Accordian from "../../accordian/index.component";
import EmptySearchResult from "../EmptySearchResult";
import SubCategorySection from "../sections/SubCategorySection";
import {
  COLOR_ASH_GREY,
  COLOR_WHITE,
} from "../../../constants/colors.constants";

function CategorySection({
  category,
  primaryTextColor,
  secondaryColor,
  primaryColor,
  secondaryTextColor,
  selectedFontFamily,
  mainCategoryRefs,
  observerConfig,
  onCategoryActivation,
  onSubCategoryActivation,
  activeSubCategory,
  activeMainCategory,
  isMobileView,
  setActiveMainCategory,
}) {
  const { t } = useTranslation();

  const handleMainCategoryIntersection = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        onCategoryActivation(category);
      }
    });
  };

  useEffect(() => {
    const mainCategoryObserver = new IntersectionObserver(
      handleMainCategoryIntersection,
      observerConfig
    );
    mainCategoryObserver.observe(mainCategoryRefs[category.id].current);

    return () => mainCategoryObserver.disconnect();

    /**
     * we only need observerConfig and id, including the rest will give
     * raise to jankiness
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [observerConfig, category.id]);

  const subCategoryRefs = (category.sub_categories || []).reduce(
    (acc, { id }) => {
      acc[id] = createRef();
      return acc;
    },
    {}
  );

  const categoryTitle = (category) => (
    <div className="section-heading-wrapper">
      <Typography
        variant="h1"
        weight="bold"
        className="section-heading"
        fontColor={primaryTextColor}
      >
        {category.name}
      </Typography>
      <Typography variant="h1" className="section-heading-desc" weight="bold">
        {t("common.totalItems", { total: category.item_count })}
      </Typography>
    </div>
  );

  const categoryWiseItems = (category) => (
    <>
      {category?.sub_categories && category.sub_categories?.length > 0
        ? null
        : category.description && (
            <div
              dangerouslySetInnerHTML={{
                __html: `${category.description}`,
              }}
              style={{
                color: secondaryTextColor,
                fontFamily: selectedFontFamily,
                fontWeight: "normal !important",
                margin: "8px 0",
              }}
            />
          )}

      {category.items?.length ? (
        <div className="category-item-list">
          {category.items.map((item) => (
            <ItemCard
              item={item}
              key={item.id}
              secondaryColor={secondaryColor}
              primaryColor={primaryColor}
              primaryTextColor={primaryTextColor}
              secondaryTextColor={secondaryTextColor}
              selectedFontFamily={selectedFontFamily}
            />
          ))}
        </div>
      ) : category.name === "Search Results" && !category.items?.length ? (
        <EmptySearchResult
          primaryTextColor={primaryTextColor}
          secondaryTextColor={secondaryColor}
        />
      ) : null}
    </>
  );

  return (
    <div
      data-main-slug={`s-${category.slug}`}
      id={category.id}
      ref={mainCategoryRefs[category.id]}
      className="element"
    >
      {category.item_count ? (
        <div
          style={{
            backgroundColor: COLOR_WHITE,
            padding: "0px 12px",
            marginBottom: "12px",
          }}
        >
          <Accordian
            summary={categoryTitle(category)}
            details={categoryWiseItems(category)}
            iconColor={COLOR_ASH_GREY}
            active={activeMainCategory === category.id}
          />
        </div>
      ) : null}

      {category.sub_categories.map((subCategory) => (
        <SubCategorySection
          key={subCategory.id}
          subCategory={subCategory}
          category={category}
          primaryTextColor={primaryTextColor}
          secondaryTextColor={secondaryTextColor}
          secondaryColor={secondaryColor}
          primaryColor={primaryColor}
          selectedFontFamily={selectedFontFamily}
          subCategoryRefs={subCategoryRefs}
          observerConfig={observerConfig}
          onSubCategoryActivation={onSubCategoryActivation}
          activeSubCategory={activeSubCategory}
          isMobileView={isMobileView}
        />
      ))}
    </div>
  );
}

export default CategorySection;
