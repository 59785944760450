import React from "react";

export default function LogoutIcon({ fill, size, className, onClickCallback }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      className={className}
    >
      <defs>
        <path
          id="7st0llb2ua"
          d="M7.994 5.6H4.8c-.442 0-.8.358-.8.8 0 .442.358.8.8.8h3.194V12c0 .442-.358.8-.8.8H.8c-.442 0-.8-.358-.8-.8V.8C0 .358.358 0 .8 0h6.394c.442 0 .8.358.8.8v4.8zM8 5.6h4.94l-2.068-2.069c-.312-.312-.312-.818 0-1.13.312-.313.819-.313 1.131 0L16 6.4l-3.997 4c-.312.312-.819.312-1.131 0-.312-.313-.312-.819 0-1.131L12.939 7.2H8V5.6z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <g>
                <g transform="translate(-32.000000, -516.000000) translate(16.000000, 112.000000) translate(16.000000, 404.000000)">
                  <circle cx="16" cy="16" r="16" fill={fill} fillOpacity="1" />
                  <g transform="translate(8.000000, 10.000000)">
                    <mask id="bcmod7q29b" fill="#fff">
                      <use xlinkHref="#7st0llb2ua" />
                    </mask>
                    <use fill="#000" xlinkHref="#7st0llb2ua" />
                    <path
                      fill="#000"
                      d="M-8 -10H24V22H-8z"
                      mask="url(#bcmod7q29b)"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
