import React from "react";

export default function NonVegDietIndicatorIcon({ fill, size, className }) {
  return (
    <svg
      width="16px"
      height="16px"
      viewBox="0 0 16 16"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none">
        <path stroke="#954A29" fill="#FFF" d="M.3.3h15.4v15.4H.3z" />
        <path fill="#934A28" d="m8 4.5 3.5 7h-7z" />
      </g>
    </svg>
  );
}
