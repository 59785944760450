import React, { useEffect, useContext } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";

// import i18next from "i18next"
import seoHelper from "common/helpers/seoHelper";
import configSelector from "common/store/selectors/configSelector";
import pageSelector from "common/store/selectors/pageSelector";
import ItemList from "../../common/item-list/index.component";
import { BaseContext } from "../../context/BaseContext";
import renderHelmet from "../../helpers/helmetHelper";
import { translateOptions } from "../../i18n";
import clone from "common/helpers/deepClone";

import "./index.component.scss";

function QRCodeMenu(props) {
  const BaseConsumer = useContext(BaseContext);

  // props
  const {
    config,
    primaryTextColor,
    catalogueLoading,
    catalogueError,
    categories,
    searchResults,
    items,
  } = props;

  // variables
  const { isMobileView, screenWidth, searchActive } = BaseConsumer;
  const pageData = pageSelector.getMenuPage({ config });
  const seoData = seoHelper(pageData.seo);
  const primaryColor = configSelector.getPrimaryColor({ config });

  // const activeLanguage = i18next.language;

  const filterSearchResults = (searchItems) => {
    /**
     * Temp fix
     * Need to get this fixed from codex/meraki-api
     */

    const filteredSearchItems = [];
    items.forEach((item) => {
      let vals = searchItems.filter((o) => o.id === item.id);
      if (vals.length > 0) {
        filteredSearchItems.push(vals[0]);
      }
    });
    return filteredSearchItems;
  };

  useEffect(() => {
    function recordPositionBeforeReload() {
      sessionStorage.setItem("scrolledSoFar", 0);
      sessionStorage.setItem("mobileScrollPosition", 0); // for mobile view autoscroll due to keyboard
    }

    window.addEventListener("beforeunload", recordPositionBeforeReload);
    return () => {
      window.removeEventListener("beforeunload", recordPositionBeforeReload);
    };

    // TODO: Need to fix
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let finalData = clone(categories);

  if (searchResults && searchActive) {
    finalData = [
      {
        id: "search-results",
        name: "Search Results",
        slug: "search-results",
        items: filterSearchResults(searchResults),
        item_count: filterSearchResults(searchResults).length,
        sub_categories: [],
      },
    ];
  }

  return (
    <div className="explore-wrapper ">
      {renderHelmet(seoData)}
      <div className="container">
        <div className="explore-container">
          <ItemList
            {...props}
            isMobileView={isMobileView}
            loading={catalogueLoading}
            primaryColor={primaryColor}
            categoryWiseItemList={finalData || []}
            primaryTextColor={primaryTextColor}
            screenWidth={screenWidth}
            catalogueError={catalogueError}
          />
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    config: state.config,
    items: state.catalogue.data ? state.catalogue.data.items : [],
    categories: state.catalogue.data ? state.catalogue.data.categories : [],
    catalogueLoading: state.catalogue.pending || state.search.pending,
    catalogueError: state.catalogue.error === null ? false : true,
    searchResults: state.search.data,
  };
};

export default withTranslation(
  ["translations"],
  translateOptions
)(connect(mapStateToProps, null)(QRCodeMenu));
