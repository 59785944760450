import React from "react";

export default function ProfileIcon({ fill, size, className }) {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.01928 14.0391C3.43362 14.0391 0.491634 16.9522 0.441777 20.5266C0.441757 20.529 0.441757 20.5315 0.441777 20.534C0.42884 20.6091 0.433982 20.6863 0.456777 20.759C0.456757 20.7615 0.456757 20.764 0.456777 20.7664C0.466648 20.7951 0.479204 20.8227 0.494277 20.8489C0.503423 20.8645 0.513439 20.8795 0.524277 20.8939C0.533644 20.9069 0.543657 20.9194 0.554277 20.9314C0.563645 20.9444 0.573658 20.957 0.584277 20.9689C0.59383 20.9794 0.603839 20.9894 0.614277 20.9989C0.628701 21.0098 0.643725 21.0198 0.659277 21.0289C0.671427 21.037 0.68394 21.0446 0.696777 21.0515C0.699277 21.0515 0.701777 21.0515 0.704277 21.0515C0.744441 21.0724 0.787366 21.0876 0.831777 21.0965C0.834277 21.0965 0.836777 21.0965 0.839277 21.0965C0.901169 21.1088 0.964884 21.1088 1.02678 21.0965H20.9918C21.0693 21.1107 21.1492 21.1056 21.2243 21.0816C21.2939 21.0612 21.358 21.0253 21.4118 20.9766C21.4169 20.9718 21.4219 20.9668 21.4268 20.9617C21.4268 20.9593 21.4268 20.9568 21.4268 20.9543C21.4372 20.9447 21.4472 20.9347 21.4568 20.9243C21.4593 20.9218 21.4618 20.9194 21.4643 20.9169C21.4721 20.9072 21.4796 20.8971 21.4867 20.8869C21.4868 20.8844 21.4868 20.8819 21.4867 20.8794C21.4948 20.8673 21.5023 20.8548 21.5092 20.8419C21.5248 20.8133 21.5373 20.7831 21.5467 20.7519C21.5641 20.691 21.5692 20.6273 21.5617 20.5644C21.5618 20.5619 21.5618 20.5595 21.5617 20.557C21.5284 16.9686 18.5802 14.0395 14.9842 14.0395H7.01924L7.01928 14.0391Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.0019 0.894531C7.99222 0.894531 5.54224 3.34454 5.54224 6.35417C5.54224 9.36163 7.99222 11.8113 11.0019 11.8113C14.0094 11.8113 16.4592 9.36162 16.4592 6.35417C16.4592 3.34454 14.0094 0.894531 11.0019 0.894531Z"
        fill="black"
      />
    </svg>
  );
}
