import React, { useRef, useState, forwardRef } from "react";

import { Dialog, Slide } from "@material-ui/core";

import getShareURL from "common/helpers/getShareURL";
import openURL from "common/helpers/openURL";
import { COLOR_WHITE, COLOR_DARK_GREY } from "../../constants/colors.constants";
import FacebookIcon from "../../assets/icons/facebook.icon";
import PinterestIcon from "../../assets/icons/pinterest.icon";
import ShareIcon from "../../assets/icons/share.icon";
import TwitterBirdIcon from "../../assets/icons/twitter-bird.icon";
import Typography from "../typography/index.component";

import "./index.component.scss";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const shareLinkList = [
  {
    id: 1101,
    name: "facebook",
    url: getShareURL(window.location.href, "facebook"),
    icon: (
      <FacebookIcon
        size={{ width: 12, height: 23 }}
        className="share-link-icon"
        fill="#3B4F88"
      />
    ),
  },
  {
    id: 1102,
    name: "twitter",
    url: getShareURL(window.location.href, "twitter"),
    icon: <TwitterBirdIcon className="share-link-icon" />,
  },
  {
    id: 1104,
    name: "pinterest",
    url: getShareURL(window.location.href, "pinterest"),
    icon: <PinterestIcon className="share-link-icon" />,
  },
];

export default function ShareWidget({ isMobileView }) {
  const [open, setOpen] = useState(false);
  const shareWidgetButton = useRef(null);
  const shareWidgetDialog = useRef(null);

  const handleClick = () => {
    const shareWidgetButtonEle = shareWidgetButton.current;
    const shareWidgetDialogEle = shareWidgetDialog.current;
    if (!isMobileView) {
      shareWidgetDialogEle.style.top = `${
        shareWidgetButtonEle.getBoundingClientRect().top + 48
      }px`;
      shareWidgetDialogEle.style.left = `${
        shareWidgetButtonEle.getBoundingClientRect().left - 160
      }px`;
    } else {
      shareWidgetDialogEle.style.setProperty("left", "0", "important");
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOnClick = (url) => {
    openURL(url);
  };

  const renderShareLink = (link) => {
    const { id, name, icon, url } = link;
    return (
      <div className="share-link" key={id} onClick={() => handleOnClick(url)}>
        {icon}
        <Typography variant="h4" weight="bold" className="share-link-text">
          {name}
        </Typography>
      </div>
    );
  };

  return (
    <div className="share-widget-wrapper">
      <div
        onClick={handleClick}
        ref={shareWidgetButton}
        className="share-widget-button"
        style={{ backgroundColor: COLOR_WHITE }}
      >
        <ShareIcon className="share-widget-icon" fill={COLOR_DARK_GREY} />
      </div>
      <Dialog
        ref={shareWidgetDialog}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        classes={{
          root: "share-widget-dialog-root",
          container: "share-widget-dialog-container",
          paper: "share-widget-dialog-paper",
        }}
      >
        <div className="share-link-list">
          {shareLinkList.map((link) => renderShareLink(link))}
        </div>
      </Dialog>
    </div>
  );
}
