import { gql } from "@apollo/client";

const GET_BIZ_INFO = gql`
  query getBizInfo {
    getBusinessInfo {
      id
      name
      currency
      isd_code
    }
  }
`;

export default {
  GET_BIZ_INFO,
};
