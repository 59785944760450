import React, { useContext } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { BaseContext } from "../../context/BaseContext";

import seoHelper from "common/helpers/seoHelper";
import configSelector from "common/store/selectors/configSelector";
import pageSelector from "common/store/selectors/pageSelector";
import PageNotFoundIcon from "../../assets/icons/404.icon";
import AppButton from "../../common/button/index.component";
import Typography from "../../common/typography/index.component";
import renderHelmet from "../../helpers/helmetHelper";
import { translateOptions } from "../../i18n";
import useRouteHook from "../../hooks/useRoute.hook";

import "./index.component.scss";

function PageNotFound(props) {
  // callbacks
  const { t } = props;

  // props
  const { config } = props;

  // variables
  const primaryColor = configSelector.getPrimaryColor({ config });
  const primaryTextColor = configSelector.getPrimaryTextColor({ config });
  const secondaryTextColor = configSelector.getSecondaryTextColor({ config });
  const pageData = pageSelector.getPageNotFoundPage({ config });
  const seoData = seoHelper(pageData.seo);
  const { menuRoute } = useContext(BaseContext);
  const { historyPush } = useRouteHook();

  const goHomeHandler = () => {
    historyPush(menuRoute);
  };

  return (
    <div className="page-not-found-wrapper">
      {renderHelmet(seoData)}
      <div className="page-not-found-container">
        <PageNotFoundIcon className="page-not-found-illustration" />
        <Typography
          variant="h1"
          weight="bold"
          className="section-heading"
          fontColor={primaryTextColor}
        >
          {t("pageNotFound.sectionHeading")}
        </Typography>
        <Typography
          variant="h2"
          weight="regular"
          className="section-message"
          fontColor={secondaryTextColor}
        >
          {t("pageNotFound.subMessageOne")}
          <br />
          {t("pageNotFound.subMessageTwo")}
        </Typography>
        <AppButton
          variant="contained"
          className="go-home-button"
          buttonColor={primaryColor}
          onClickCallback={goHomeHandler}
        >
          <Typography
            variant="h3"
            weight="bold"
            className="section-message"
            fontColor="#f7fafc"
          >
            {t("pageNotFound.goHome")}
          </Typography>
        </AppButton>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    config: state.config,
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default withTranslation(
  ["translations"],
  translateOptions
)(connect(mapStateToProps, mapDispatchToProps)(PageNotFound));
