export const ITEM_DUMMY_DATA = [
  { id: 1, placeholder: 1 },
  { id: 2, placeholder: 2 },
  { id: 3, placeholder: 3 },
  { id: 4, placeholder: 4 },
  { id: 5, placeholder: 5 },
  { id: 6, placeholder: 6 },
  { id: 7, placeholder: 7 },
  { id: 8, placeholder: 8 },
  { id: 9, placeholder: 9 },
  { id: 10, placeholder: 10 },
  { id: 11, placeholder: 11 },
  { id: 12, placeholder: 12 },
  { id: 13, placeholder: 13 },
];

export const ITEM_CATEGORY_DUMMY_DATA = [
  { id: 1, placeholder: 1 },
  { id: 2, placeholder: 2 },
  { id: 3, placeholder: 3 },
  { id: 4, placeholder: 4 },
  { id: 5, placeholder: 5 },
  { id: 6, placeholder: 6 },
  { id: 7, placeholder: 7 },
  { id: 8, placeholder: 8 },
];

export const ITEM_MINIMAL_DATA = [1, 2, 3];

export const RATINGS_ARRAY_LONG = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
