export const ORDERING_TYPE_ONLINE = "online";
export const ORDERING_TYPE_QR = "qr";

export const DELIVERY = "delivery";
export const PICKUP = "pickup";

export const ADD_ADDRESS_VIEW = "add_address_view";
export const CHOOSE_ADDRESS_VIEW = "choose_address_view";
export const ADDRESS_TAG_HOME = "home";
export const ADDRESS_TAG_OTHERS = "others";
export const ADDRESS_TAG_WORK = "work";

export const FORMAT_TYPE_UNICODE = "unicode";
export const FORMAT_TYPE_SVG = "svg";
