import React from "react";
import AppButton from "../button/index.component";
import configSelector from "common/store/selectors/configSelector";

import "./index.component.scss";

export default function FloatingCta({
  children,
  config,
  onClickCallback = () => {},
}) {
  const primaryColor = configSelector.getPrimaryColor({ config });

  return (
    <AppButton
      className="float-btn-wrapper"
      variant="contained"
      buttonColor={primaryColor}
      onClickCallback={onClickCallback}
    >
      {children}
    </AppButton>
  );
}
