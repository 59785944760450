import React, { useState } from "react";
import { useForm } from "react-hook-form";

import { Dialog } from "@material-ui/core";

import { bright_90, increaseBrightness } from "common/helpers/adjustColor";
import upsdkService from "common/services/upsdkService";

import CrossIcon from "../../../assets/icons/cross.icon";
import KeyIcon from "../../../assets/icons/key.icon";
import AppButton from "../../../common/button/index.component";
import InputField from "../../../common/fields/input-field/index.component";
import ServerError from "../../../common/server-error/index.component";
import Typography from "../../../common/typography/index.component";
import { showNotification } from "../../../helpers/utils";

import "./index.component.scss";

export default function ChangePasswordDialog(props) {
  const { register, handleSubmit, errors } = useForm();
  const [loading, setLoading] = useState(false);

  // callbacks
  const { t, toggleChangePasswordCallback } = props;

  // props
  const {
    isChangePassword,
    primaryColor,
    primaryTextColor,
    secondaryTextColor,
    tertiaryColor,
    userData,
    secondaryColor,
  } = props;

  const onSubmit = (data) => {
    if (!userData) {
      return;
    }
    setLoading(true);
    const { OldPassword, NewPassword, ConfirmPassword } = data;
    upsdkService
      .changePassword({
        confirmPassword: ConfirmPassword,
        newPassword: NewPassword,
        oldPassword: OldPassword,
        phone: userData.phone,
      })
      .then((response) => {
        if (!response.success) {
          showNotification(response.error_message, "error");
          return;
        }
        showNotification(t("changePassword.successMessage"), "success");
        toggleChangePasswordCallback();
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Dialog
      onClose={toggleChangePasswordCallback}
      aria-labelledby="change-password-dialog"
      open={isChangePassword}
      classes={{ paper: "change-password-dialog" }}
    >
      <div className="change-password-form">
        <div className="dialog-header">
          <div className="heading-wrapper">
            <Typography
              variant="h1"
              weight="bold"
              className="form-heading"
              fontColor={primaryTextColor}
            >
              {t("changePassword.heading")}
            </Typography>
            <CrossIcon
              fill="#A3A3A4"
              size={{ width: 16, heigth: 16 }}
              className="close-dialog-icon"
              onClickCallback={toggleChangePasswordCallback}
            />
          </div>
          <Typography
            variant="h3"
            weight="regular"
            className="form-sub-heading"
            fontColor={secondaryTextColor}
          >
            {t("changePassword.subHeading")}
          </Typography>
        </div>
        <div className="dialog-content">
          <form onSubmit={handleSubmit(onSubmit)}>
            <InputField
              type="password"
              refForm={register({ required: true })}
              name="OldPassword"
              errors={errors}
              label={
                <Typography
                  variant="h4"
                  weight="bold"
                  fontColor={primaryTextColor}
                  isRequired={true}
                >
                  {t("changePassword.oldPassword")}
                </Typography>
              }
              inputIcon={<KeyIcon className="field-icon" />}
              primaryTextColor={primaryTextColor}
              iconBackgroundColor={increaseBrightness(primaryColor, bright_90)}
            />
            <InputField
              type="password"
              refForm={register({ required: true })}
              name="NewPassword"
              errors={errors}
              label={
                <Typography
                  variant="h4"
                  weight="bold"
                  fontColor={primaryTextColor}
                  isRequired={true}
                >
                  {t("changePassword.newPassword")}
                </Typography>
              }
              inputIcon={<KeyIcon className="field-icon" />}
              primaryTextColor={primaryTextColor}
              iconBackgroundColor={increaseBrightness(
                secondaryColor,
                bright_90
              )}
            />
            <InputField
              type="password"
              refForm={register({ required: true })}
              name="ConfirmPassword"
              errors={errors}
              label={
                <Typography
                  variant="h4"
                  weight="bold"
                  fontColor={primaryTextColor}
                  isRequired={true}
                >
                  {t("changePassword.confirmPassword")}
                </Typography>
              }
              inputIcon={<KeyIcon className="field-icon" />}
              primaryTextColor={primaryTextColor}
              iconBackgroundColor={increaseBrightness(tertiaryColor, bright_90)}
            />
          </form>
        </div>
        {false && <ServerError message="" />}
        <div className="dialog-footer">
          <AppButton
            fullWidth
            variant="contained"
            isLoading={loading}
            buttonColor={primaryColor}
            className="continue-button"
            onClickCallback={handleSubmit(onSubmit)}
          >
            <Typography variant="h2" weight="bold" fontColor="#FFFFFF">
              {t("changePassword.continue")}
            </Typography>
          </AppButton>
        </div>
      </div>
    </Dialog>
  );
}
