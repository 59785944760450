import React from "react";

import dayjsUtils from "@date-io/dayjs";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import classNames from "classnames";
import LanguageHelper from "common/helpers/language.helper";

import "./index.component.scss";

export default function DateField(props) {
  // Props
  const {
    label,
    className,
    inputIcon,
    primaryTextColor,
    refForm,
    name,
    errors,
    helperText,
    disabled,
    minDate,
    maxDate,
    selectedDate,
    onDateChange,
    shouldDisableDate,
    config,
  } = props;

  // Callbacks
  const { iconBackgroundColor } = props;

  // Variables
  const activeLanguage = LanguageHelper.getLanguage(config);

  const handleDateChange = (date) => {
    onDateChange(date);
  };

  return (
    <div
      className={classNames([
        `date-field-wrapper ${className || ""}`,
        {
          disabled: disabled,
        },
      ])}
    >
      {inputIcon && (
        <div
          className="field-icon-wrapper"
          style={{ backgroundColor: iconBackgroundColor }}
        >
          {inputIcon}
        </div>
      )}
      <MuiPickersUtilsProvider utils={dayjsUtils} locale={activeLanguage}>
        <KeyboardDatePicker
          shouldDisableDate={shouldDisableDate}
          format="DD/MM/YYYY"
          margin="normal"
          minDate={minDate}
          maxDate={maxDate}
          label={label}
          name={name}
          inputRef={refForm}
          inputProps={{ disabled: true }}
          error={errors && errors[name]}
          classes={{ root: "date-field" }}
          value={selectedDate}
          style={{ margin: 8, color: primaryTextColor }}
          helperText={errors && errors[name] ? helperText : null}
          onChange={handleDateChange}
        />
      </MuiPickersUtilsProvider>
    </div>
  );
}
