import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { COLOR_SPUN_PEARL } from "../../../constants/colors.constants";
import ChevronIcon from "../../../assets/icons/chevron.icon";
import Typography from "../../typography/index.component";

function CategoryNavigator({
  category,
  headerRect,
  activeMainCategory,
  activeSubCategory,
  handleScrollToActiveSection,
  handleAutoScroll,
}) {
  const eleRef = useRef();
  const { i18n } = useTranslation();
  const direction = i18n.dir();
  const [left, setLeft] = useState(0);
  const hasSubCategories =
    category.sub_categories && category.sub_categories.length > 0;

  const handleMouseHover = () => {
    const parentLeft = headerRect.left;
    const child = eleRef?.current?.getBoundingClientRect();
    if (child) {
      const childLeft = child.left;
      setLeft(childLeft - parentLeft);
    }
  };

  useEffect(() => {
    if (!category.slug === "search-results") {
      if (category.id === activeMainCategory) {
        handleAutoScroll(eleRef.current);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeMainCategory, category.id]);

  if (category?.slug === "search-results") return null;

  return (
    <div
      key={category.id}
      ref={eleRef}
      className={
        direction === "rtl" ? "rtl-category category" : "ltr-category category"
      }
      onMouseOver={handleMouseHover}
      data-active-navigator={category.id}
      onClick={() => handleScrollToActiveSection(category.id)}
    >
      <div
        className={`category-link ${
          activeMainCategory === category.id ? "active" : ""
        }`}
      >
        <Typography variant="h3" className="category-name" weight="regular">
          {category.name}
        </Typography>
        {category.item_count ? (
          <Typography
            variant="h3"
            className="category-item-count"
            weight="regular"
          >
            ({category.item_count})
          </Typography>
        ) : null}
        {hasSubCategories && (
          <ChevronIcon
            fill={COLOR_SPUN_PEARL}
            className="chevron-down"
            size={{ width: 10, height: 10 }}
          />
        )}
      </div>
      {hasSubCategories && (
        <div
          id="sub-cat-dialog"
          className="sub-category-list"
          style={{ left: left }}
        >
          {category.sub_categories.map((subCategory) => (
            <SubCategoryNavigator
              key={subCategory.id}
              category={category}
              subCategory={subCategory}
              activeSubCategory={activeSubCategory}
              handleScrollToActiveSection={handleScrollToActiveSection}
            />
          ))}
        </div>
      )}
    </div>
  );
}

function SubCategoryNavigator({
  subCategory,
  activeSubCategory,
  handleScrollToActiveSection,
}) {
  function handleSubCateogryScroll(e) {
    /**
     * to avoid propagating the click to parent, causes unwanted behaviour
     */
    e.stopPropagation();
    handleScrollToActiveSection(subCategory.id);
  }

  return (
    <div
      onClick={handleSubCateogryScroll}
      className={`sub-category-link ${
        activeSubCategory === subCategory.id ? "sub-category-active" : ""
      }`}
    >
      <Typography variant="h3" weight="regular" className="sub-category-text">
        {subCategory.name}
      </Typography>
    </div>
  );
}

export default CategoryNavigator;
