import React from "react";

export default function PastOrdersIcon({ fill, size, className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      className={className}
    >
      <defs>
        <path
          id="km39th4z3a"
          d="M2.144 0C.967 0 0 .967 0 2.144v10.563c0 1.176.967 2.143 2.144 2.143h9.712c1.177 0 2.144-.967 2.144-2.143V2.144C14 .967 13.033 0 11.856 0H2.144zM5.22 2.225c.19.006.37.085.503.22.288.29.288.758 0 1.047L4.237 4.978c-.14.14-.329.218-.526.218-.198 0-.387-.079-.526-.218l-.741-.74c-.14-.14-.219-.329-.219-.527 0-.197.079-.387.219-.526.29-.288.757-.288 1.047 0l.22.219.96-.959c.132-.133.31-.212.498-.219l.05-.001zm5.808.747c.197-.001.387.076.527.215.14.138.22.327.22.524 0 .199-.078.389-.218.529s-.33.218-.529.217H8.482c-.197 0-.385-.08-.524-.22s-.216-.329-.215-.526c.001-.407.331-.738.74-.74h2.545zM5.17 5.942c.206-.009.407.068.554.213.14.14.22.33.22.527s-.08.387-.22.526L4.237 8.694c-.14.14-.329.218-.526.218-.198 0-.387-.079-.526-.218l-.741-.746c-.288-.29-.288-.757 0-1.047.29-.288.757-.288 1.047 0l.22.215.96-.96c.133-.132.31-.208.498-.214zm5.86.74c.197-.001.386.076.527.215.14.14.219.329.22.526 0 .198-.079.388-.219.529-.14.14-.33.218-.529.216H8.482c-.197 0-.385-.08-.524-.22-.139-.139-.216-.328-.215-.525 0-.408.33-.74.74-.741h2.545zm0 3.711c.197 0 .388.077.528.217s.219.33.218.529c0 .197-.08.386-.22.525-.14.138-.33.216-.527.215H2.965c-.408-.002-.739-.332-.74-.74 0-.197.077-.386.215-.526.139-.14.328-.219.525-.22h8.063z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <g>
                <g transform="translate(-32.000000, -180.000000) translate(16.000000, 112.000000) translate(16.000000, 68.000000)">
                  <circle cx="16" cy="16" r="16" fill={fill} fillOpacity="1" />
                  <g transform="translate(9.000000, 9.000000)">
                    <use
                      fill="#000"
                      fillRule="nonzero"
                      xlinkHref="#km39th4z3a"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
