import { RATINGS_ARRAY_LONG } from "../mock-data/index.mock-data";
import {
  COLOR_RATING_ONE,
  COLOR_RATING_TWO,
  COLOR_RATING_THREE,
  COLOR_RATING_FOUR,
  COLOR_RATING_FIVE,
  COLOR_RATING_SIX,
  COLOR_RATING_SEVEN,
  COLOR_RATING_EIGHT,
  COLOR_RATING_NINE,
  COLOR_RATING_TEN,
} from "../constants/colors.constants";

const COLORS = [
  COLOR_RATING_ONE,
  COLOR_RATING_TWO,
  COLOR_RATING_THREE,
  COLOR_RATING_FOUR,
  COLOR_RATING_FIVE,
  COLOR_RATING_SIX,
  COLOR_RATING_SEVEN,
  COLOR_RATING_EIGHT,
  COLOR_RATING_NINE,
  COLOR_RATING_TEN,
];

class FeedbackHelper {
  static getRatingsRange() {
    return RATINGS_ARRAY_LONG;
  }

  static getRatingsColor(value) {
    const index = value - 1;
    return COLORS[index];
  }
}

export default FeedbackHelper;
