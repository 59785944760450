import React, { useContext, useState } from "react";
import { NavLink } from "react-router-dom";

import { Drawer } from "@material-ui/core";

import { bright_90, increaseBrightness } from "common/helpers/adjustColor";
import { getMenuLinks } from "common/helpers/getFooterLinks";
import configSelector from "common/store/selectors/configSelector";
import pageSelector from "common/store/selectors/pageSelector";
import upsdkSelector from "common/store/selectors/upsdkSelector";
import { BaseContext } from "../../context/BaseContext";
import LanguageHelper from "common/helpers/language.helper";
import BookmarkIcon from "../../assets/icons/bookmark.icon";
import ProfileIcon from "../../assets/icons/profile-dark.icon";
import FAQIcon from "../../assets/icons/faq.icon";
import LogoutIcon from "../../assets/icons/logout.icon";
import OrderNowIcon from "../../assets/icons/order-now.icon";
import PastOrdersIcon from "../../assets/icons/past-order.icon";
import ReferAndEarnIcon from "../../assets/icons/referearn.icon";
import SettingsIcon from "../../assets/icons/settings.icon";
import WalletMenuIcon from "../../assets/icons/wallet-menu.icon";
import LanguageIcon from "../../assets/icons/languages.icon";
import AppStoreDownloadIcon from "../../assets/images/AppStore.svg";
import PlayStoreDownloadIcon from "../../assets/images/PlayStore.svg";
import { useDialog } from "../../context/DialogContext";
import CurrencyHelper from "common/helpers/currency.helper";
import AppButton from "../button/index.component";
import SelectField from "../fields/select-field/index.component";
import Typography from "../typography/index.component";
import ScreenHelper from "../../helpers/screen.helper";

import "./index.component.scss";

export default function SideBar(props) {
  // callbacks
  const {
    t,
    i18n,
    location,
    toggleDrawerCallback,
    userLogoutCallback,
    showAuthDialogCallback,
    customRoutes,
    upsdk,
    isMobileView,
  } = props;

  // props
  const {
    open,
    userData,
    primaryColor,
    primaryTextColor,
    secondaryTextColor,
    authToken,
    secondaryColor,
    config,
  } = props;
  const { showDialog, hideDialog } = useDialog();
  const initialLanguage = LanguageHelper.getLanguage(config);

  const [languageDropdownState, setChangeLanguageDropdown] = useState(false);
  const [activeLan, setActiveLanguage] = useState(initialLanguage);

  // variables
  const walletConfig = configSelector.getWalletConfig({ config });
  const menuPageConfig = pageSelector.getMenuPage({ config });
  const profilePageConfig = pageSelector.getProfilePage({ config });
  const referEarnPageConfig = pageSelector.getReferEarnPage({ config });
  const referEarnBool = configSelector.getReferralBool({ config });
  const orderHistoryPageConfig = pageSelector.getOrderHistoryPage({ config });
  const favouritePageConfig = pageSelector.getFavouritesPage({ config });
  const supportedLanguages = upsdkSelector.getSupportedLanguages({ upsdk });
  const leftLink = configSelector.getTopHeaderLeftLink({ config });
  const customMenuLinks = getMenuLinks(
    configSelector.getCustomPages({ config })
  );
  const { menuRoute, landingScreenEnabled, isQrModeEnabled } =
    useContext(BaseContext);

  const handleOnConfirm = () => {
    userLogoutCallback();
    hideDialog();
    toggleDrawerCallback();
  };

  const handleUserLogout = () => {
    showDialog({
      title: t("common.confirm"),
      buttonText: t("common.ok"),
      buttonColor: primaryColor,
      description: t("common.logoutAlert"),
      onClickCallback: () => handleOnConfirm(),
    });
  };

  const handleLoginClick = () => {
    showAuthDialogCallback("signIn");
    toggleDrawerCallback();
  };

  // const handleSignUpClick = () => {
  //   showAuthDialogCallback("signUp");
  //   toggleDrawerCallback();
  // };

  const toggleChangeLanguageDropdown = () => {
    setChangeLanguageDropdown(!languageDropdownState);
  };

  const onLanguageChangeHandler = (language) => {
    LanguageHelper.onLanguageChange(i18n, language);
    setActiveLanguage(language);
  };

  const UserInfo = () => (
    <div className="user-info-section">
      <div className="default-avatar" style={{ backgroundColor: primaryColor }}>
        {userData ? (
          <Typography variant="h2" weight="bold" className="avatar-text">
            {userData.name.charAt(0) || ""}
          </Typography>
        ) : null}
      </div>
      {userData ? <User /> : <UserPlaceholder />}
    </div>
  );

  const User = () => (
    <div className="user-info">
      <Typography
        variant="h2"
        weight="bold"
        className="user-name"
        fontColor={primaryTextColor}
      >
        {userData.name}
      </Typography>
      <Typography
        variant="h3"
        weight="regular"
        className="user-mobile"
        fontColor={secondaryTextColor}
      >
        {userData.phone}
      </Typography>
    </div>
  );

  const UserPlaceholder = () => (
    <div className="user-info">
      <Typography
        variant="h3"
        weight="bold"
        className="user-name"
        fontColor={primaryTextColor}
        onClickCallback={handleLoginClick}
      >
        {t("sidebar.login")}
      </Typography>
      <Typography
        variant="h4"
        weight="regular"
        className="user-mobile"
        fontColor={secondaryTextColor}
      >
        {t("sidebar.loggedOutStateMessage")}
      </Typography>
    </div>
  );

  const DownloadApp = () => (
    <div className="downloadApp-btn-wrapper">
      {leftLink.iOSAppLink && (
        <a
          target="_blank"
          rel="noreferrer"
          href={leftLink.iOSAppLink}
          className="download-app-link"
        >
          <img
            src={AppStoreDownloadIcon}
            alt="app-store"
            className="download-btn app-store"
          />
        </a>
      )}
      {leftLink.androidAppLink && (
        <a
          target="_blank"
          rel="noreferrer"
          href={leftLink.androidAppLink}
          className="download-app-link"
        >
          <img
            src={PlayStoreDownloadIcon}
            className="download-btn play-store"
            alt="app-store"
          />
        </a>
      )}
    </div>
  );

  const ChangeLanguageDropdown = () => (
    <SelectField
      name="changeLanguage"
      open={languageDropdownState}
      defaultValue={initialLanguage}
      primaryTextColor={primaryTextColor}
      iconBackgroundColor={increaseBrightness(secondaryColor, bright_90)}
      options={supportedLanguages.map((lang, index) => {
        return { title: lang.toUpperCase(), value: lang };
      })}
      onChange={(event) => {
        onLanguageChangeHandler(event.target.value);
      }}
      toggleTimeSlotDropdownCallback={toggleChangeLanguageDropdown}
      value={activeLan}
    />
  );

  const renderLanguageChanger = () =>
    supportedLanguages.length > 1 && (
      <div
        className="menu-item"
        activeStyle={{ backgroundColor: primaryColor }}
        activeClassName="active-menu-item"
        to="/past-orders"
      >
        <LanguageIcon
          className="menu-icon"
          fill={increaseBrightness(primaryColor, bright_90)}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            alignItems: "center",
          }}
        >
          <Typography
            className="menu-name"
            variant="h2"
            weight="regular"
            fontColor={secondaryTextColor}
          >
            {t("sidebar.changeLanguage")}
          </Typography>
          {ChangeLanguageDropdown()}
        </div>
      </div>
    );

  if (isQrModeEnabled) {
    return (
      <Drawer
        classes={{ paper: "side-bar-wrapper" }}
        anchor={i18n.dir() === "rtl" ? "right" : "left"}
        open={open}
        onClose={toggleDrawerCallback}
      >
        <div className="container">
          <div className="menu-list no-auth">{renderLanguageChanger()}</div>
          {(leftLink.iOSAppLink || leftLink.androidAppLink) && isMobileView && (
            <DownloadApp />
          )}
        </div>
      </Drawer>
    );
  }

  if (!authToken) {
    return (
      <Drawer
        classes={{ paper: "side-bar-wrapper" }}
        anchor={i18n.dir() === "rtl" ? "right" : "left"}
        open={open}
        onClose={toggleDrawerCallback}
      >
        <div className="container">
          <div>
            <div className="login-alert-section">
              <div className="profile-icon-wrapper">
                <ProfileIcon className="field-icon" />
              </div>
              <div className="login-content">
                <Typography
                  className="user-account-heading"
                  variant="h2"
                  weight="semibold"
                >
                  {t("sidebar.userAccount")}
                </Typography>
                <Typography
                  className="user-account-text"
                  variant="h3"
                  weight="regular"
                >
                  {t("sidebar.loginDescription")}
                </Typography>

                <div className="button-wrapper">
                  <AppButton
                    fullWidth
                    variant="contained"
                    className="login-button"
                    buttonColor={primaryColor}
                    onClickCallback={handleLoginClick}
                  >
                    <Typography variant="h3" weight="bold" fontColor="#FFFFFF">
                      {t("sidebar.loginNow")}
                    </Typography>
                  </AppButton>
                </div>
              </div>
            </div>

            <div className="menu-list no-auth">
              {isMobileView && renderLanguageChanger()}
              {customMenuLinks && customMenuLinks.length
                ? customMenuLinks.map((customMenu) => (
                    <NavLink
                      className="menu-item"
                      activeStyle={{ backgroundColor: primaryColor }}
                      activeClassName="active-menu-item"
                      to={customMenu.path}
                    >
                      <FAQIcon
                        className="menu-icon"
                        fill={
                          ScreenHelper.isCustomScreen(
                            location.pathname,
                            customMenu.path
                          )
                            ? "#FFFFFF"
                            : increaseBrightness(primaryColor, bright_90)
                        }
                      />
                      <Typography
                        className="menu-name"
                        variant="h2"
                        weight="regular"
                        fontColor={
                          ScreenHelper.isCustomScreen(
                            location.pathname,
                            customMenu.path
                          )
                            ? "#FFFFFF"
                            : secondaryTextColor
                        }
                      >
                        {customMenu.name}
                      </Typography>
                    </NavLink>
                  ))
                : null}
            </div>
          </div>
          {(leftLink.iOSAppLink || leftLink.androidAppLink) && isMobileView && (
            <DownloadApp />
          )}
        </div>
      </Drawer>
    );
  }

  return (
    <Drawer
      classes={{ paper: "side-bar-wrapper" }}
      anchor={i18n.dir() === "rtl" ? "right" : "left"}
      open={open}
      onClose={toggleDrawerCallback}
    >
      <UserInfo />
      <div className="menu-list">
        {!isMobileView && menuPageConfig.enabled && (
          <NavLink
            /**
             * the below condition in exact makes sure the menu tab is highlighted on
             * every category and subcategory as well.
             */
            exact={
              !ScreenHelper.isMenuScreen(
                customRoutes,
                location.pathname,
                landingScreenEnabled
              )
            }
            className="menu-item"
            activeStyle={{ backgroundColor: primaryColor }}
            activeClassName="active-menu-item"
            to={menuRoute}
          >
            <OrderNowIcon
              className="menu-icon"
              fill={
                ScreenHelper.isMenuScreen(
                  customRoutes,
                  location.pathname,
                  landingScreenEnabled
                )
                  ? "#ffffff"
                  : increaseBrightness(primaryColor, bright_90)
              }
            />
            <Typography
              className="menu-name"
              variant="h2"
              weight="regular"
              fontColor={
                ScreenHelper.isMenuScreen(
                  customRoutes,
                  location.pathname,
                  landingScreenEnabled
                )
                  ? "#FFFFFF"
                  : secondaryTextColor
              }
            >
              {t("sidebar.menu")}
            </Typography>
          </NavLink>
        )}
        {orderHistoryPageConfig.enabled && (
          <NavLink
            className="menu-item"
            activeStyle={{ backgroundColor: primaryColor }}
            activeClassName="active-menu-item"
            to="/past-orders"
          >
            <PastOrdersIcon
              className="menu-icon"
              fill={
                ScreenHelper.isPastOrdersScreen(location.pathname)
                  ? "#ffffff"
                  : increaseBrightness(primaryColor, bright_90)
              }
            />
            <Typography
              className="menu-name"
              variant="h2"
              weight="regular"
              fontColor={
                ScreenHelper.isPastOrdersScreen(location.pathname)
                  ? "#FFFFFF"
                  : secondaryTextColor
              }
            >
              {t("sidebar.pastOrders")}
            </Typography>
          </NavLink>
        )}

        {favouritePageConfig.enabled && (
          <NavLink
            className="menu-item"
            activeStyle={{ backgroundColor: primaryColor }}
            activeClassName="active-menu-item"
            to="/favourites"
          >
            <BookmarkIcon
              className="menu-icon"
              fill={
                ScreenHelper.isFavouritesScreen(location.pathname)
                  ? "#ffffff"
                  : increaseBrightness(primaryColor, bright_90)
              }
            />
            <Typography
              className="menu-name"
              variant="h2"
              weight="regular"
              fontColor={
                ScreenHelper.isFavouritesScreen(location.pathname)
                  ? "#FFFFFF"
                  : secondaryTextColor
              }
            >
              {t("common.favorites")}
            </Typography>
          </NavLink>
        )}
        {walletConfig.enable && (
          <NavLink
            className="menu-item"
            activeStyle={{ backgroundColor: primaryColor }}
            activeClassName="active-menu-item"
            to="/wallet"
          >
            <WalletMenuIcon
              className="menu-icon"
              fill={
                ScreenHelper.isWalletScreen(location.pathname)
                  ? "#FFFFFF"
                  : increaseBrightness(primaryColor, bright_90)
              }
            />
            <Typography
              className="menu-name"
              variant="h2"
              weight="regular"
              fontColor={
                ScreenHelper.isWalletScreen(location.pathname)
                  ? "#FFFFFF"
                  : secondaryTextColor
              }
            >
              {t("sidebar.walletBalance")}
            </Typography>
            {userData && (
              <Typography
                variant="h2"
                weight="regular"
                className="menu-badge"
                style={{
                  backgroundColor: ScreenHelper.isWalletScreen(
                    location.pathname
                  )
                    ? "#FFFFFF"
                    : primaryColor,
                }}
                fontColor={
                  ScreenHelper.isWalletScreen(location.pathname)
                    ? primaryColor
                    : "#FFFFFF"
                }
              >
                {CurrencyHelper.format(userData.balance)}
              </Typography>
            )}
          </NavLink>
        )}
        {referEarnPageConfig.enabled && referEarnBool && (
          <NavLink
            className="menu-item"
            activeStyle={{ backgroundColor: primaryColor }}
            activeClassName="active-menu-item"
            to="/refer-and-earn"
          >
            <ReferAndEarnIcon
              className="menu-icon"
              fill={
                ScreenHelper.isReferAndEarnScreen(location.pathname)
                  ? "#ffffff"
                  : increaseBrightness(primaryColor, bright_90)
              }
            />
            <Typography
              className="menu-name"
              variant="h2"
              weight="regular"
              fontColor={
                ScreenHelper.isReferAndEarnScreen(location.pathname)
                  ? "#FFFFFF"
                  : secondaryTextColor
              }
            >
              {t("sidebar.referearn")}
            </Typography>
          </NavLink>
        )}
        {profilePageConfig.enabled && (
          <NavLink
            className="menu-item"
            activeStyle={{ backgroundColor: primaryColor }}
            activeClassName="active-menu-item"
            to="/profile"
          >
            <SettingsIcon
              className="menu-icon"
              fill={
                ScreenHelper.isProfileScreen(location.pathname)
                  ? "#FFFFFF"
                  : increaseBrightness(primaryColor, bright_90)
              }
            />
            <Typography
              className="menu-name"
              variant="h2"
              weight="regular"
              fontColor={
                ScreenHelper.isProfileScreen(location.pathname)
                  ? "#FFFFFF"
                  : secondaryTextColor
              }
            >
              {t("sidebar.profile")}
            </Typography>
          </NavLink>
        )}
        {customMenuLinks && customMenuLinks.length
          ? customMenuLinks.map((customMenu) => (
              <NavLink
                className="menu-item"
                activeStyle={{ backgroundColor: primaryColor }}
                activeClassName="active-menu-item"
                to={customMenu.path}
              >
                <FAQIcon
                  className="menu-icon"
                  fill={
                    ScreenHelper.isCustomScreen(
                      location.pathname,
                      customMenu.path
                    )
                      ? "#FFFFFF"
                      : increaseBrightness(primaryColor, bright_90)
                  }
                />
                <Typography
                  className="menu-name"
                  variant="h2"
                  weight="regular"
                  fontColor={
                    ScreenHelper.isCustomScreen(
                      location.pathname,
                      customMenu.path
                    )
                      ? "#FFFFFF"
                      : secondaryTextColor
                  }
                >
                  {customMenu.name}
                </Typography>
              </NavLink>
            ))
          : null}
        {isMobileView && renderLanguageChanger()}
        {userData && (
          <div className="menu-item" onClick={handleUserLogout}>
            <LogoutIcon
              className="menu-icon"
              fill={increaseBrightness(primaryColor, bright_90)}
            />
            <Typography
              className="menu-name"
              variant="h2"
              weight="regular"
              fontColor={secondaryTextColor}
            >
              {t("sidebar.logout")}
            </Typography>
          </div>
        )}
      </div>
      {(leftLink.iOSAppLink || leftLink.androidAppLink) && isMobileView && (
        <DownloadApp />
      )}
    </Drawer>
  );
}
