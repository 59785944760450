import { gql } from "@apollo/client";

const GET_ALL_ITEMS = gql`
  query GetAllItems(
    $keyword: String
    $fulfilment_mode: FulfillmentMode
    $sort_by: String
    $filter_by: [Int!]
  ) {
    getAllItems(
      sort_by: $sort_by
      filter_by: $filter_by
      fulfillment_mode: $fulfilment_mode
      keyword: $keyword
    ) {
      category {
        id
        name
        slug
        sort_order
      }
      item_title
      current_stock
      food_type
      has_options
      id
      image_url
      image_blur_hash
      item_desc
      item_price
      price_descriptor
      recommended
      slug
      sort_order
      unit_weight
    }
  }
`;

const GET_CATALOGUE = gql`
  query GetCatalog(
    $fulfilment_mode: FulfillmentMode
    $limit: Int
    $offset: Int
    $sort_by: String
    $filter_by: [Int!]
  ) {
    getAllItems(
      sort_by: $sort_by
      filter_by: $filter_by
      fulfillment_mode: $fulfilment_mode
    ) {
      category {
        id
        name
        slug
        sort_order
      }
      item_title
      current_stock
      food_type
      has_options
      id
      image_url
      image_blur_hash
      item_desc
      item_price
      price_descriptor
      recommended
      slug
      sort_order
      unit_weight
    }
    getAllCategories(limit: $limit, offset: $offset) {
      id
      name
      description
      image
      item_count
      load_from_web
      slug
      sort_order
      web_url
      sub_categories {
        description
        id
        image
        item_count
        name
        slug
        sort_order
      }
    }
    getSortFilters {
      filters {
        group
        options {
          id
          title
        }
      }
      sort_by
    }
  }
`;

const GET_ITEM_OPTIONS = gql`
  query getItemOptions($item_id: Int!) {
    getItemOptionGroups(item_id: $item_id) {
      id
      title
      description
      default
      max_selectable
      min_selectable
      sort_order
      options {
        id
        title
        food_type
        price
        sort_order
        current_stock
        available
        option_group_id
        nested_options_group {
          id
          title
          description
          default
          max_selectable
          min_selectable
          sort_order
        }
      }
    }
  }
`;

const GET_ITEM_BY_ID = gql`
  query getItemById($item_id: Int!) {
    getItemById(item_id: $item_id) {
      current_stock
      display_price
      food_type
      has_options
      id
      image_blur_hash
      image_url
      item_desc
      item_price
      item_title
      likes
      markup_price
      price_descriptor
      service_tax_rate
      slug
      sort_order
      vat_rate
      extras {
        id
        name
        key_values {
          id
          key
          value
        }
      }
    }
  }
`;

export default {
  GET_CATALOGUE,
  GET_ITEM_OPTIONS,
  GET_ALL_ITEMS,
  GET_ITEM_BY_ID,
};
