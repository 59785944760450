import loadScript from "../../helpers/loadScript";
import upsdkService from "../upsdkService";

let initialConfig = {
  theme: {
    color: "#000",
  },
};

function initRazorPay(razorpay_options = initialConfig) {
  const url = `https://checkout.razorpay.com/v1/checkout.js`;
  loadScript(url, () => {
    if (razorpay_options) {
      upsdkService.setPGConfig("razorpay", razorpay_options);
    }
  });
}

const paymentGatewayService = {
  initRazorPay,
};

export default paymentGatewayService;
