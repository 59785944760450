import {
  SET_SUB_LOCALITY,
  CLEAR_SUB_LOCALITY,
  SET_LOCATION_DIALOG,
  UNSET_LOCATION_DIALOG,
  SET_SELECTED_ADDRESS,
  CLEAR_SELECTED_ADDRESS,
} from "../actions/ui";

const initialState = {
  subLocality: null,
  showLocationDialog: false,
  selectedAddress: null,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_SUB_LOCALITY: {
      const newState = {
        ...state,
        subLocality: action.data,
      };
      return newState;
    }
    case CLEAR_SUB_LOCALITY: {
      const newState = {
        ...state,
        subLocality: null,
      };
      return newState;
    }

    case SET_LOCATION_DIALOG: {
      const newState = {
        ...state,
        showLocationDialog: true,
      };
      return newState;
    }
    case UNSET_LOCATION_DIALOG: {
      const newState = {
        ...state,
        showLocationDialog: false,
      };
      return newState;
    }

    case SET_SELECTED_ADDRESS: {
      const newState = {
        ...state,
        selectedAddress: action.data,
      };
      return newState;
    }
    case CLEAR_SELECTED_ADDRESS: {
      const newState = {
        ...state,
        selectedAddress: null,
      };
      return newState;
    }

    default:
      return state;
  }
};
