import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { COLOR_SPUN_PEARL } from "../../../constants/colors.constants";
import configSelector from "common/store/selectors/configSelector";
import googlePlacesService from "common/services/googlePlaces";
import CrossIcon from "../../../assets/icons/cross.icon";
import Typography from "../../typography/index.component";
import SelectLocationMap from "./select-location-map";
import AddAddressForm from "./add-address-form";
import { useDialog } from "../../../context/DialogContext";
import upsdkService from "common/services/upsdkService";
import { ADDRESS_TAG_HOME } from "../../../constants/enums.constants";
import SearchedAddresses from "./search-address";
import ChevronIcon from "../../../assets/icons/chevron.icon";
import InputField from "../../fields/input-field/index.component";
import { BaseContext } from "../../../context/BaseContext";

import "./index.component.scss";

export default function AddAddressView({
  primaryColor,
  primaryTextColor,
  secondaryTextColor,
  config,
  subLocality,
  bizInfo,
  fetchUser,
  closeAddressViewFlow,
  handleFlowComplete,
  userData,
  setSelectedAddress,
  fulfilmentType,
  selectedStore,
}) {
  const [activeTab, setActiveTab] = useState(ADDRESS_TAG_HOME);
  const [position, setPosition] = useState({
    lat: subLocality?.lat || selectedStore?.lat || 0,
    lng: subLocality?.lng || selectedStore?.lng || 0,
  });
  const [newlyAddedAddressId, setNewlyAddedAddressId] = useState(null);
  const { showDialog, hideDialog } = useDialog();
  const [isSaveAddressLoading, setSaveAddressLoading] = useState(false);
  const [mapSelectedSubLocality, setMapSelectedSubLocality] = useState("");
  const [mapSelectedLandmark, setMapSelectedLandmark] = useState("");
  const [searchLocation, setSearchLocation] = useState(false);
  const [showMap, setShowMap] = useState(true);
  const [addressSuggestions, setAddressSuggestions] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const { isMobileView } = useContext(BaseContext);
  const googleMapApiKey = configSelector.getGoogleMapsKey({ config });
  const { t, i18n } = useTranslation();
  const searchInputRef = useRef();

  const onMapChangeHandler = (params) => {
    const { lat, lng } = params.center;
    setPosition({ lat, lng });
  };

  const onFormClick = () => {
    if (isMobileView) setShowMap(false);
  };

  const validateAddress = ({ lat, lng }) => {
    return new Promise((resolve, reject) => {
      upsdkService
        .getDeliverableStores(lat, lng, "_", fulfilmentType)
        .then((response) => {
          if (selectedStore.id === response?.store?.id) {
            resolve();
          } else {
            reject();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    });
  };

  useEffect(() => {
    const { lat, lng } = position;
    googlePlacesService
      .getSubLocality(lat, lng)
      .then((response) => {
        setMapSelectedSubLocality(response?.formatted_address || "");
        setMapSelectedLandmark(
          response?.address_components.find(
            (item) => !item?.types?.includes("landmark")
          )?.short_name
        );
      })
      .catch((err) => console.log(err, "error"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [position]);

  useEffect(() => {
    const newlyAddededAddress = (userData?.addresses || []).find(
      (address) => address.id === newlyAddedAddressId
    );
    if (newlyAddededAddress) {
      setSelectedAddress(newlyAddededAddress);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  const handleAddressSelection = ({ place_id }) => {
    googlePlacesService.getDetails(place_id).then((addressDetails) => {
      const { lat, lng } = addressDetails;
      setPosition({ lat, lng });
    });
  };

  const onSubmit = (data) => {
    setSaveAddressLoading(true);
    const lat = position.lat;
    const lng = position.lng;
    validateAddress({ lat, lng })
      .then(() => {
        /**
         * set position here since the user choosen location is valid and the nearest store is same as selected store
         */

        const addressLine1 = data.addressLine1;
        const addressLine2 = data.addressLine2;
        const landmark = data.landmark;
        const city = data.city;
        const pincode = data.pincode;
        let tag = activeTab;
        if (activeTab === "others") {
          tag = data.label;
        }
        let newAddress = {
          lat: lat,
          lng: lng,
          sub_locality: mapSelectedSubLocality,
          address_1: addressLine1,
          address_2: addressLine2 || landmark || "",
          landmark: landmark || "",
          city: city || "",
          pin: pincode,
          tag: tag,
        };
        if (userData && !userData.guest_checkout) {
          upsdkService
            .saveAddress(newAddress)
            .then((response) => {
              if (response.data.address_id) {
                const addressId = response.data.address_id;
                setNewlyAddedAddressId(addressId);
                fetchUser();
                setSelectedAddress({ id: addressId, ...newAddress });
                handleFlowComplete();
              }
            })
            .catch((error) => console.log(error, "error save address"))
            .finally(() => {
              setSaveAddressLoading(false);
            });
        } else {
          setSelectedAddress(newAddress);
          handleFlowComplete();
        }
      })
      .catch(() => {
        /**
         * done let the user set position here since the user choosen location is invalid and the nearest store is not same as selected store
         */
        showDialog({
          title: t("header.storeNotDeliverableHeading"),
          description:
            "Cannot find a store near selected location.Please change the location and try again.",
          buttonText: t("header.storeNotDeliverableConfirm"),
          buttonColor: primaryColor,
          onClickCallback: () => {
            hideDialog();
          },
        });
        setSaveAddressLoading(false);
      });
  };

  const onLocationSelect = (address) => {
    handleAddressSelection(address);
    setSearchLocation(false);
  };

  const handleBack = () => {
    setShowMap(true);
    setSearchLocation(false);
  };

  const clearSearchQuery = () => {
    setSearchQuery("");
    setAddressSuggestions([]);
  };

  useEffect(() => {
    if (searchLocation && searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [searchLocation]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const googlePlacesAutoComplete = useCallback(
    (address) => {
      if (address.trim()) {
        googlePlacesService
          .search(address, bizInfo.isd_code)
          .then((response) => {
            setAddressSuggestions(response);
          })
          .catch((error) => console.log(error));
      } else {
        setAddressSuggestions([]);
      }
    },
    [bizInfo]
  );

  const handleOnChange = (event) => {
    setSearchQuery(event.target.value);
    googlePlacesAutoComplete(event.target.value);
  };

  return (
    <div className="add-address-wrapper">
      {searchLocation ? (
        <Fragment>
          <div className="search-address-heading">
            <InputField
              type="text"
              name="subLocality"
              refForm={searchInputRef}
              className="search-address-input"
              onChange={handleOnChange}
              placeholder="Search your location"
              primaryTextColor={primaryTextColor}
              helperText={t("common.requiredFieldHelperText")}
              inputProps={{
                value: searchQuery,
                disableUnderline: true,
                startAdornment: (
                  <ChevronIcon
                    onClickCallback={handleBack}
                    rotate={i18n.dir() === "rtl" ? "270deg" : "90deg"}
                    className="checkron-back-icon"
                    fill={COLOR_SPUN_PEARL}
                    size={{ width: 16, height: 16 }}
                  />
                ),
                endAdornment: (
                  <Fragment>
                    {searchQuery && (
                      <CrossIcon
                        fill={COLOR_SPUN_PEARL}
                        size={{ width: 12, heigth: 12 }}
                        className="clear-search-icon"
                        onClickCallback={clearSearchQuery}
                      />
                    )}
                  </Fragment>
                ),
              }}
            />
          </div>
          <SearchedAddresses
            bizInfo={bizInfo}
            open={true}
            onLocationSelect={onLocationSelect}
            setSearchLocation={setSearchLocation}
            primaryTextColor={primaryTextColor}
            secondaryTextColor={secondaryTextColor}
            primaryColor={primaryColor}
            addressSuggestions={addressSuggestions}
          />
        </Fragment>
      ) : (
        <Fragment>
          <div className="add-address-heading">
            <div className="add-address-heading-left">
              {!showMap && (
                <ChevronIcon
                  onClickCallback={handleBack}
                  rotate={i18n.dir() === "rtl" ? "270deg" : "90deg"}
                  className="checkron-back-icon"
                  fill={COLOR_SPUN_PEARL}
                  size={{ width: 16, height: 16 }}
                />
              )}

              <Typography
                variant="h1"
                weight="bold"
                className="add-address-heading-text"
                fontColor={primaryTextColor}
              >
                {t("sidebar.selectYourLocation")}
              </Typography>
            </div>
            <div className="add-address-heading-right">
              <CrossIcon
                fill={COLOR_SPUN_PEARL}
                size={{ width: 16, heigth: 16 }}
                className="close-address-drawer-icon"
                onClickCallback={closeAddressViewFlow}
              />
            </div>
          </div>
          {showMap && (
            <div className="add-address-content">
              <SelectLocationMap
                className="select-location-map-wrapper"
                mapKey={googleMapApiKey}
                primaryColor={primaryColor}
                setPosition={setPosition}
                position={position}
                onMapChangeHandler={onMapChangeHandler}
              />
            </div>
          )}

          <div
            className="selected-address-info"
            onClick={() => setSearchLocation(true)}
          >
            <div className="heading-wrapper">
              <Typography
                variant="h2"
                weight="bold"
                fontColor={primaryTextColor}
              >
                {mapSelectedLandmark}
              </Typography>
              <Typography variant="h3" weight="bold" fontColor={primaryColor}>
                {t("checkout.change")}
              </Typography>
            </div>
            <Typography
              variant="h4"
              weight="semiBold"
              fontColor={secondaryTextColor}
            >
              {mapSelectedSubLocality}
            </Typography>
          </div>

          <div className="add-address-footer">
            <AddAddressForm
              onFormClick={onFormClick}
              onSubmit={onSubmit}
              primaryColor={primaryColor}
              primaryTextColor={primaryTextColor}
              secondaryTextColor={secondaryTextColor}
              position={position}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              handleAddressSelection={handleAddressSelection}
              isSaveAddressLoading={isSaveAddressLoading}
            />
          </div>
        </Fragment>
      )}
    </div>
  );
}
