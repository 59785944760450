import React from "react";
import { useTranslation } from "react-i18next";

import { ITEM_DUMMY_DATA } from "../../mock-data/index.mock-data";
import CarouselItem from "../carousel-item/index.component";
import Carousel from "../carousel/index.component";
import Typography from "../typography/index.component";

import "./index.component.scss";

export default function RecommendationsWidget({
  primaryColor,
  primaryTextColor,
  secondaryTextColor,
  recommendations,
  viewPorts,
  isMobileView,
  markFavoriteCallback,
  markUnfavoriteCallback,
  customizeItemClickCallback,
}) {
  // callbacks
  const { t } = useTranslation();

  if (recommendations && !recommendations.length) {
    return null;
  }

  return (
    <div className="recommendations-widget-wrapper">
      {!!recommendations && !!recommendations.length && (
        <Typography
          variant="h1"
          weight="bold"
          className="section-heading"
          fontColor={primaryTextColor}
        >
          {t("common.recommendationItems")}
        </Typography>
      )}
      {recommendations ? (
        <RecommendationList
          viewPorts={viewPorts}
          isMobileView={isMobileView}
          recommendations={recommendations}
          primaryColor={primaryColor}
          primaryTextColor={primaryTextColor}
          secondaryTextColor={secondaryTextColor}
          markFavoriteCallback={markFavoriteCallback}
          markUnfavoriteCallback={markUnfavoriteCallback}
          customizeItemClickCallback={customizeItemClickCallback}
        />
      ) : (
        <ShimmerEffect viewPorts={viewPorts} primaryColor={primaryColor} />
      )}
    </div>
  );
}

const RecommendationList = ({
  primaryColor,
  primaryTextColor,
  secondaryTextColor,
  recommendations,
  viewPorts,
  isMobileView,
  markFavoriteCallback,
  markUnfavoriteCallback,
  customizeItemClickCallback,
}) => {
  return (
    <Carousel
      items={recommendations}
      viewPorts={viewPorts}
      primaryColor={primaryColor}
    >
      {recommendations.map((item) => (
        <CarouselItem
          item={item}
          isMobileView={isMobileView}
          primaryTextColor={primaryTextColor}
          secondaryTextColor={secondaryTextColor}
          markFavoriteCallback={markFavoriteCallback}
          markUnfavoriteCallback={markUnfavoriteCallback}
          customizeItemClickCallback={customizeItemClickCallback}
          key={item.id}
        />
      ))}
    </Carousel>
  );
};

const ShimmerEffect = ({ viewPorts, primaryColor }) => {
  return (
    <Carousel
      items={ITEM_DUMMY_DATA}
      viewPorts={viewPorts}
      primaryColor={primaryColor}
    >
      {ITEM_DUMMY_DATA.map((item) => (
        <CarouselItem key={item.id} />
      ))}
    </Carousel>
  );
};
