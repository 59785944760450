const ANALYTICS_EVENTS = {
  APP_LAUNCHED: "APP_LAUNCHED",
  DELIVERY_STORE_SELECTED: "DELIVERY_STORE_SELECTED",
  NO_STORES_NEARBY: "NO_STORES_NEARBY",
  NEAREST_STORE_CLOSED_TEMP: "NEAREST_STORE_CLOSED_TEMP",
  NEAREST_STORE_CLOSED_TODAY: "NEAREST_STORE_CLOSED_TODAY",
  ITEM_SEARCH: "ITEM_SEARCH",
  REORDER: "REORDER",
  COUPON_SUCCESS: "COUPON_SUCCESS",
  COUPON_FAILED: "COUPON_FAILED",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAILED: "LOGIN_FAILED",
  PASSWORD_RESET: "PASSWORD_RESET",
  RESEND_OTP: "RESEND_OTP",
  SIGNUP_START: "SIGNUP_START",
  SIGNUP_COMPLETE: "SIGNUP_COMPLETE",
  SOCIAL_LOGIN_SIGNUP_START: "SOCIAL_LOGIN_SIGNUP_START",
  SOCIAL_LOGIN_SIGNUP_COMPLETE: "SOCIAL_LOGIN_SIGNUP_COMPLETE",
  SOCIAL_LOGIN_SUCCESS: "SOCIAL_LOGIN_SUCCESS",
  SOCIAL_LOGIN_FAILED: "SOCIAL_LOGIN_FAILED",
  WALLET_RELOAD_INIT: "WALLET_RELOAD_INIT",
  SUCCESSFUL_WALLET_RELOAD: "SUCCESSFUL_WALLET_RELOAD",
  FAILED_WALLET_RELOAD: "FAILED_WALLET_RELOAD",
  REFERRAL_SENT: "REFERRAL_SENT",
  LOGOUT: "LOGOUT",
  PRODUCT_CLICKED: "PRODUCT_CLICKED",
  CART_INIT: "CART_INIT",
  ADD_TO_CART: "ADD_TO_CART",
  VIEW_CART: "VIEW_CART",
  REMOVE_FROM_CART: "REMOVE_FROM_CART",
  ORDER_PLACED: "ORDER_PLACED",
  ITEM_LIKED: "ITEM_LIKED",
  ITEM_UNLIKED: "ITEM_UNLIKED",
  FEEDBACK_SUBMITTED: "FEEDBACK_SUBMITTED", // Not Implemented
  CHECKOUT_INIT: "CHECKOUT_INIT",
  PROFILE_UPDATED: "PROFILE_UPDATED",
  BANNER_CLICKED: "BANNER_CLICKED",
  ADDRESS_SELECTED: "ADDRESS_SELECTED",

  PICKUP_STORE_SELECTED: "PICKUP_STORE_SELECTED",
  MULTIPLE_DELIVERY_STORES: "MULTIPLE_DELIVERY_STORES",
  FILTER_APPLIED: "FILTER_APPLIED",
  SORT_APPLIED: "SORT_APPLIED",
  ADD_MORE_ITEMS: "ADD_MORE_ITEMS",
  VIEW_MORE_DESC: "VIEW_MORE_DESC",
  SELECTED_PAYMENT_MODE: "SELECTED_PAYMENT_MODE",
  SELECTED_DELIVERY_ADDRESS: "SELECTED_DELIVERY_ADDRESS",
  ADD_MONEY_CHECKOUT: "ADD_MONEY_CHECKOUT",
  CATEGORY_DETAIL: "CATEGORY_DETAIL",
};

const subscribers = {};

function publish(eventName, data) {
  if (!Array.isArray(subscribers[eventName])) {
    return;
  }
  subscribers[eventName].forEach((callback) => {
    callback(data);
  });
}

function subscribe(eventName, callback) {
  if (!Array.isArray(subscribers[eventName])) {
    subscribers[eventName] = [];
  }
  subscribers[eventName].push(callback);
}

function unsubscribe(eventName, handler) {
  if (!Array.isArray(subscribers[eventName])) {
    subscribers[eventName] = [];
  }

  subscribers[eventName] = subscribers[eventName].filter((h) => h !== handler);
}

const analyticsPubSub = {
  subscribe,
  unsubscribe,
  publish,
};

export default analyticsPubSub;
export { ANALYTICS_EVENTS };
