import clone from "./deepClone";
import Item from "../store/models/Item";

/**
 * All data transforming operations
 */
export default class DataTransformer {
  static getDietType(id) {
    switch (id) {
      case "1":
        return "veg";
      case "2":
        return "non-veg";
      case "3":
        return "egg";
      default:
        return "NA";
    }
  }

  static getCatalogue(data, sortBy = null, filters = []) {
    function sortItems(items) {
      let data = [];
      let outOfStock = [];
      items.forEach((item) => {
        if (item.current_stock === 0) {
          outOfStock.push(item);
        } else {
          data.push(item);
        }
      });
      if (!sortBy) {
        data.sort((a, b) => a.sort_order - b.sort_order);
      }
      return [...data, ...outOfStock];
    }

    const categoryResponse = clone(data.getAllCategories);
    const itemData = clone(data.getAllItems.map((item) => new Item(item)));
    const optionsData = clone(data.getSortFilters);
    const categoryData = [];

    //remove all empty categories
    categoryResponse.forEach((category) => {
      let sub_categories = [];
      let categoryItems = itemData.filter(
        (item) => item.category.id === category.id
      );
      category.items = sortItems(categoryItems);
      category.item_count = categoryItems.length;

      category.sub_categories &&
        category.sub_categories.forEach((sub_category) => {
          let items = itemData.filter(
            (item) => item.category.id === sub_category.id
          );
          sub_category.items = sortItems(items);
          sub_category.item_count = items.length;

          if (items && items.length > 0) {
            sub_categories.push(sub_category);
          }
        });

      if (
        (categoryItems && categoryItems.length > 0) ||
        (sub_categories && sub_categories.length > 0)
      ) {
        category.sub_categories = sub_categories;
        categoryData.push(category);
      }
    });

    return {
      items: itemData,
      categories: categoryData,
      options: optionsData,
      appliedOptions: { sort_by: sortBy, filters: filters },
    };
  }

  static getSearchResults(items) {
    const transformedData = items.map((item) => {
      return new Item(item, false, false, true, false, false);
    });

    const sortedData = transformedData.sort((a, b) => {
      if (a.current_stock === 0 && b.current_stock !== 0) {
        return 1;
      } else if (b.current_stock === 0 && a.current_stock !== 0) {
        return -1;
      }

      return a.sort_order - b.sort_order;
    });

    return sortedData;
  }
}
