import React, { useState } from "react";

import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import upsdkSelector from "common/store/selectors/upsdkSelector";
import { useTranslation } from "react-i18next";
import Typography from "../typography/index.component";
import CrossIcon from "../../assets/icons/cross.icon";
import {
  COLOR_SPUN_PEARL,
  COLOR_WHITE,
  COLOR_ASH_GREY,
} from "../../constants/colors.constants";
import FeedbackHelper from "../../helpers/feedback.helper";
import AppButton from "../button/index.component";
import { bright_70, increaseBrightness } from "common/helpers/adjustColor";

import "./index.component.scss";

const defaultFeedbackValues = {
  selectedRating: null,
  comments: "",
  choiceText: "",
};

function NpsFeedback({
  open,
  primaryTextColor,
  primaryColor,
  bizInfo,
  handleClose,
  handleFeedbackSubmission,
}) {
  const [feedbackValues, setFeedbackValues] = useState(defaultFeedbackValues);
  const { t } = useTranslation();
  const feedbackChoices = upsdkSelector.getFeedbackChoices({ upsdk: bizInfo });

  const handleRatingSelection = (selectedRating) => {
    setFeedbackValues({ ...feedbackValues, selectedRating });
  };

  const onChangeHandler = (event) => {
    const { name, value } = event.target;
    setFeedbackValues({ ...feedbackValues, [name]: value });
  };

  const submitHandler = () => {
    if (feedbackValues.selectedRating > 6) {
      feedbackValues.choiceText = "";
    }
    handleFeedbackSubmission(feedbackValues);
  };

  const { selectedRating, comments, choiceText } = feedbackValues;

  return (
    <Dialog
      open={open}
      keepMounted
      onClose={handleClose}
      classes={{
        root: "nps-feedback-dialog-root",
        container: "nps-feedback-dialog-container",
        paper: "nps-feedback-dialog-paper",
      }}
    >
      <div className="nps-feedback-header">
        <Typography variant="h1" weight="bold" fontColor={primaryTextColor}>
          {t("feedback.sectionHeading")}
        </Typography>

        <CrossIcon
          fill={COLOR_SPUN_PEARL}
          size={{ width: 16, heigth: 16 }}
          className="nps-feedback-close-icon"
          onClickCallback={handleClose}
        />
      </div>
      <div className="nps-feedback-body">
        <Typography variant="h3" weight="regular" fontColor={COLOR_ASH_GREY}>
          {t("feedback.subMessageOne")}
        </Typography>

        <div className="nps-feedback-rating-wrapper">
          {FeedbackHelper.getRatingsRange().map((item, index) => (
            <Rating
              value={item}
              selectedRating={selectedRating}
              handleRatingSelection={handleRatingSelection}
              key={index}
            />
          ))}
        </div>

        <TextField
          variant="standard"
          placeholder={t("feedback.subMessageTwo")}
          classes={{ root: "nps-feedback-comment" }}
          multiline
          minRows={4}
          maxRows={4}
          value={comments}
          onChange={onChangeHandler}
          name="comments"
          InputProps={{ disableUnderline: true }}
        />
        {selectedRating && selectedRating < 7 && (
          <Select
            fullWidth
            disableUnderline
            variant="standard"
            value={choiceText}
            displayEmpty
            name="choiceText"
            onChange={onChangeHandler}
            classes={{ root: "nps-feedback-choice-text" }}
            renderValue={
              choiceText !== ""
                ? undefined
                : () => (
                    <Placeholder>{t("feedback.subMessageThree")}</Placeholder>
                  )
            }
          >
            {feedbackChoices?.map((choice) => (
              <MenuItem key={choice.id} value={choice.text}>
                {choice.text}
              </MenuItem>
            ))}
          </Select>
        )}
      </div>

      <div className="nps-feedback-footer">
        <AppButton
          fullWidth
          variant="contained"
          isDisabled={selectedRating === null}
          className="nps-feedback-submit-button"
          buttonColor={primaryColor}
          onClickCallback={submitHandler}
        >
          <Typography variant="h3" weight="bold" fontColor={COLOR_WHITE}>
            {t("feedback.submit")}
          </Typography>
        </AppButton>
      </div>
    </Dialog>
  );
}

function Rating({ value, handleRatingSelection, selectedRating }) {
  const color = FeedbackHelper.getRatingsColor(value);
  const activeColor =
    selectedRating !== null && selectedRating !== value
      ? increaseBrightness(color, bright_70)
      : color;

  return (
    <div
      onClick={() => handleRatingSelection(value)}
      style={{ backgroundColor: activeColor }}
      className="nps-feedback-rating"
    >
      <Typography variant="h4" weight="regular" fontColor={COLOR_WHITE}>
        {value}
      </Typography>
    </div>
  );
}

const Placeholder = ({ children }) => {
  return <div className="nps-feedback-choice-text-placeholder">{children}</div>;
};

export default NpsFeedback;
