import graphqlService from "../../services/graphql/graphqlService";

export const FETCH_CATALOGUE_PENDING = "FETCH_CATALOGUE_PENDING";
export const FETCH_CATALOGUE_SUCCESS = "FETCH_CATALOGUE_SUCCESS";
export const FETCH_CATALOGUE_ERROR = "FETCH_CATALOGUE_ERROR";

function fetchCataloguePending() {
  return {
    type: FETCH_CATALOGUE_PENDING,
  };
}

function fetchCatalogueSuccess(catalogue) {
  return {
    type: FETCH_CATALOGUE_SUCCESS,
    payload: catalogue,
  };
}

function fetchCatalogueError(error) {
  return {
    type: FETCH_CATALOGUE_ERROR,
    error: error,
  };
}

export default function fetchCatalogue(sortBy, filters = [], fulfilmentType) {
  return (dispatch) => {
    dispatch(fetchCataloguePending());
    graphqlService
      .getCatalogue(sortBy, filters, fulfilmentType)
      .then((response) => {
        dispatch(fetchCatalogueSuccess(response));
      })
      .catch((error) => {
        dispatch(fetchCatalogueError(error));
      });
  };
}
