import React from "react";

import "./index.component.scss";

export default function Loader({ loaderColor, style }) {
  const loaderStyles = {
    border: `2px solid ${loaderColor}`,
    borderColor: `${loaderColor} transparent transparent transparent`,
  };

  return (
    <div className="app-loader" style={style}>
      <div style={{ ...loaderStyles, ...style }} />
      <div style={{ ...loaderStyles, ...style }} />
      <div style={{ ...loaderStyles, ...style }} />
      <div style={{ ...loaderStyles, ...style }} />
    </div>
  );
}
