class FooterHelper {
  static enableMore(storeLocatorPageConfig, footerLinks, externalPageLinks) {
    if (
      storeLocatorPageConfig.enabled ||
      footerLinks.length ||
      externalPageLinks.length
    ) {
      return true;
    }
    return false;
  }

  static enableContactUs(contactUs) {
    for (let link in contactUs) {
      if (contactUs[link].trim()) {
        return true;
      }
    }
    return false;
  }

  static enableFollowUs(socialMediaLinks, copyrightText) {
    if (socialMediaLinks.length || copyrightText.trim()) {
      return true;
    }
    return false;
  }
}

export default FooterHelper;
