import React, { useRef, useEffect, useState, forwardRef } from "react";

import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Slide,
  Dialog,
} from "@material-ui/core";

import ChevronGroupIcon from "../../assets/icons/chevron-group.icon";
import CrossIcon from "../../assets/icons/cross.icon";
import FiltersIcon from "../../assets/icons/filters.icon";
import {
  CHECKED_CHECKBOX_ICON,
  UNCHECKED_CHECKBOX_ICON,
} from "../../constants/image.constants";
import AppButton from "../button/index.component";
import Typography from "../typography/index.component";
import deepClone from "common/helpers/deepClone";
import { useTranslation } from "react-i18next";
import {
  COLOR_SPUN_PEARL,
  COLOR_WHITE,
} from "../../constants/colors.constants";

import "./index.component.scss";

export default function FilterBy({
  applyFilterCallback,
  clearAllFiltersCallback,
  options,
  isMobileView,
  primaryColor,
  secondaryColor,
  secondaryTextColor,
  isSearchExpanded,
  headerColorConfig,
  filterBy,
}) {
  const [open, setOpen] = useState(false);
  const [filters, setFilters] = useState([]);
  const filterByButton = useRef(null);
  const filterByDialog = useRef(null);
  const { t, i18n } = useTranslation();

  const handleClick = () => {
    const filterByButtonEle = filterByButton.current;
    const filterByDialogEle = filterByDialog.current;
    const screenWidth = window.innerWidth;

    if (isMobileView) {
      filterByDialogEle.style.left = `0px`;
    } else {
      if (i18n.dir() === "rtl") {
        const position =
          screenWidth - filterByButtonEle.getBoundingClientRect().right;
        filterByDialogEle.style.right = `${position}px`;
      } else {
        const position = filterByButtonEle.getBoundingClientRect().left;
        filterByDialogEle.style.left = `${position}px`;
      }
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (option) => {
    let data = deepClone(filters);
    if (data.includes(option.id)) {
      data = data.filter((e) => e !== option.id);
    } else {
      data.push(option.id);
    }
    setFilters(data);
  };

  const onApplyFilter = () => {
    applyFilterCallback(filters);
    handleClose();
  };

  const onClearFilter = () => {
    clearAllFiltersCallback();
    handleClose();
  };

  useEffect(() => {
    setFilters(filterBy);
  }, [filterBy]);

  if (!options || !options.filters || !options.filters.length) {
    return null;
  }

  return (
    <div className="filter-by-wrapper">
      <div
        ref={filterByButton}
        className="filter-by-button"
        onClick={handleClick}
        style={{ position: isSearchExpanded ? "static" : "relative" }}
      >
        <FiltersIcon className="button-icon" fill={primaryColor} />
        {!!filters.length && (
          <div
            className="filter-applied-indicator"
            style={{ backgroundColor: secondaryColor }}
          />
        )}
        <Typography
          variant="h3"
          weight="regular"
          className="filter-name"
          fontColor={headerColorConfig.textColor}
        >
          {t("common.filterBy")}
        </Typography>
        <ChevronGroupIcon
          fill={headerColorConfig.iconColor}
          className="chevron-icon"
        />
      </div>
      <Dialog
        ref={filterByDialog}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        classes={{
          root: "filter-by-dialog-root",
          container: "filter-by-dialog-container",
          paper: "filter-by-dialog-paper",
        }}
      >
        <div className="filter-by-title-wrapper">
          <CrossIcon
            fill={COLOR_SPUN_PEARL}
            size={{ width: 16, heigth: 16 }}
            className="close-dialog-icon"
            onClickCallback={handleClose}
          />
        </div>
        <div className="filter-by-option-container">
          {options?.filters?.map((filter) => (
            <FormGroup
              key={filter.group}
              column="true"
              className="filter-by-option-list"
            >
              <Typography
                variant="h2"
                weight="bold"
                className="filter-title"
                fontColor={secondaryColor}
              >
                {filter.group}
              </Typography>
              {filter?.options?.map((option) => {
                const isChecked = filters.includes(option.id);
                return (
                  <FormControlLabel
                    key={option.id}
                    checked={isChecked}
                    classes={{ root: "filter-by-container" }}
                    control={
                      <Checkbox
                        icon={
                          <img src={UNCHECKED_CHECKBOX_ICON} alt="checkbox" />
                        }
                        checkedIcon={
                          <img src={CHECKED_CHECKBOX_ICON} alt="uncheckbox" />
                        }
                        onChange={() => handleChange(option)}
                        name={option.title}
                      />
                    }
                    label={
                      <Typography
                        variant="h2"
                        weight={isChecked ? "bold" : "regular"}
                        fontColor={secondaryTextColor}
                      >
                        {option.title}
                      </Typography>
                    }
                  />
                );
              })}
            </FormGroup>
          ))}
        </div>
        <div className="filter-by-button-wrapper">
          <AppButton
            fullWidth
            variant="outlined"
            className="filter-action-button"
            buttonColor={primaryColor}
            onClickCallback={onClearFilter}
          >
            <Typography variant="h3" weight="regular" fontColor={primaryColor}>
              {t("filter.clearAll")}
            </Typography>
          </AppButton>
          <AppButton
            fullWidth
            variant="contained"
            className="filter-action-button"
            buttonColor={primaryColor}
            onClickCallback={onApplyFilter}
          >
            <Typography variant="h3" weight="regular" fontColor={COLOR_WHITE}>
              {t("filter.apply")}
            </Typography>
          </AppButton>
        </div>
      </Dialog>
    </div>
  );
}

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
