import React from "react";
import { Route, Switch } from "react-router-dom";
import QRCodeBaseLayout from "../common/qr-code-base-layout/index.component";
import PageNotFound from "../views/page-not-found/index.component";
import QRCodeMenu from "../views/qr-code-menu/index.component";
import ItemDetails from "../views/item-details/index.component";

export default function QRCodeOrderingRoutes() {
  return (
    <QRCodeBaseLayout>
      <Switch>
        <Route exact path="/item-list/:id/:slug">
          <ItemDetails />
        </Route>

        <Route path="/">
          <QRCodeMenu />
        </Route>

        <Route exact path="/:catSlug">
          <QRCodeMenu />
        </Route>

        <Route exact path="/:catSlug/:subCatSlug">
          <QRCodeMenu />
        </Route>

        <Route>
          <PageNotFound />
        </Route>
      </Switch>
    </QRCodeBaseLayout>
  );
}
