import React, { memo, useEffect } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { translateOptions } from "../../i18n";
import { withRouter } from "react-router-dom";
import pageSelector from "common/store/selectors/pageSelector";
import renderComponents from "../../helpers/renderComponent";
import renderHelmet from "../../helpers/helmetHelper";
import seoHelper from "common/helpers/seoHelper";
import "./index.component.scss";

function LandingScreen(props) {
  const { config } = props;

  const landingScreenConfig = pageSelector.getLandingPage({ config });
  const seoData = seoHelper(landingScreenConfig?.seo);

  const scrollToPreviousPostion = () => {
    const landingScrollPos = Number(sessionStorage.getItem("landingScrollPos"));
    if (landingScrollPos && landingScrollPos !== 0) {
      const contentEle = document.getElementById("content-wrapper");
      if (contentEle) {
        contentEle.scrollTop = landingScrollPos;
        sessionStorage.setItem("landingScrollPos", 0);
      }
    }
  };

  useEffect(() => {
    setTimeout(() => {
      scrollToPreviousPostion();
    }, 0);
  }, []);

  return (
    <div className="landing-screen-wrapper">
      {renderHelmet(seoData)}
      <div className="content-wrapper" id="content-wrapper">
        {renderComponents(landingScreenConfig?.components)}
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    config: state.config,
  };
}

export default withRouter(
  withTranslation(
    ["translations"],
    translateOptions
  )(connect(mapStateToProps, null)(memo(LandingScreen)))
);
