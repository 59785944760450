import {
  ApolloClient,
  HttpLink,
  ApolloLink,
  InMemoryCache,
  concat,
} from "@apollo/client";
import LocalStorageHelper from "../../helpers/localStorage.helper";
import { store } from "../../store";
import configSelector from "../../store/selectors/configSelector";
import { ORDERING_TYPE_QR } from "../../../themes/frodo/src/constants/enums.constants";

const config = (window && window.ROOT_CONFIG) || {};
const uri = configSelector.getGraphqlBase({ config });
const token = configSelector.getAuthToken({ config });
const mode = configSelector.getMode({ config });
const state = LocalStorageHelper.getQrModeState();

const httpLink = new HttpLink({ uri });

const middleware = new ApolloLink((operation, forward) => {
  const headers = {
    authorization: token,
    "x-use-lang": LocalStorageHelper.getLanguageFromLocalStorage(),
  };

  if (mode === ORDERING_TYPE_QR && state) {
    headers.state = state;
  }

  const selected_store = store.getState().upsdk.selected_store;
  if (selected_store) {
    headers.location_id = selected_store.id;
  }

  operation.setContext(() => ({
    headers: headers,
  }));

  return forward(operation);
});

export const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: concat(middleware, httpLink),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "no-cache",
    },
  },
});
