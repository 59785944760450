import React from "react";
import { Toaster } from "react-hot-toast";

import "./index.component.scss";

export default function AppToast(props) {
  //variables
  const { selectedFontFamily } = props;

  return (
    <Toaster
      position="top-right"
      toastOptions={{
        className: "app-toast-wrapper",
        success: {
          style: {
            padding: "12px",
            color: "#ffffff",
            backgroundColor: "#419D3E",
            fontFamily: selectedFontFamily,
            fontWeight: 400,
          },
          icon: "",
        },
        error: {
          style: {
            padding: "12px",
            color: "#ffffff",
            backgroundColor: "#E5481C",
            fontFamily: selectedFontFamily,
            fontWeight: 400,
          },
          icon: "",
        },
      }}
    />
  );
}
