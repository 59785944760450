import isTimeBetweenNow from "./isTimeBetweenNow";
import deliveryTimeService from "../deliveryTimeService";
import dayjs from "dayjs";

export default function ({ time, date, slot }) {
  let deliveryDateTime = date.clone();
  if (time) {
    deliveryDateTime = deliveryDateTime
      .set("hour", time.hour())
      .set("minute", time.minute())
      .set("second", time.second());
  }
  if (slot) {
    let isFuture = date.isAfter(dayjs(), "day");
    if (isFuture) {
      let openingArray = slot.start_time.split(":");
      deliveryDateTime = deliveryDateTime
        .set("hour", openingArray[0])
        .set("minute", openingArray[1]);
    } else {
      //check if selected slot is between now.
      let isBetweenNow = isTimeBetweenNow(slot.start_time, slot.end_time);
      if (isBetweenNow) {
        let offset = deliveryTimeService.getOffsetTime();
        deliveryDateTime = deliveryDateTime
          .set("hour", dayjs().hour())
          .set("minute", dayjs().minute());
        deliveryDateTime = deliveryDateTime.add(offset, "milliseconds");
      }
    }
  }
  return deliveryDateTime;
}
