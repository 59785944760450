import React from "react";
export default function FavouriteContainedIcon({ fill, size, className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      className={className}
    >
      <defs>
        <path
          id="mr405yirda"
          d="M11.447 1c-1.373 0-2.692.662-3.552 1.7C7.035 1.662 5.715 1 4.342 1 1.911 1 0 2.97 0 5.496c0 3.082 2.684 5.608 6.75 9.425L7.895 16l1.144-1.079c4.066-3.817 6.75-6.343 6.75-9.425C15.79 2.97 13.88 1 11.447 1z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <g>
                <g>
                  <g>
                    <g transform="translate(-668 -746) translate(32 586) translate(0 136) translate(350) translate(278 16) translate(8 8)">
                      <path d="M0 0H16V16H0z" />
                      <mask id="2olkbdpcbb" fill="#fff">
                        <use xlinkHref="#mr405yirda" />
                      </mask>
                      <use
                        fill="#000"
                        fillRule="nonzero"
                        xlinkHref="#mr405yirda"
                      />
                      <path
                        fill={fill}
                        d="M0 0H16V16H0z"
                        mask="url(#2olkbdpcbb)"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
