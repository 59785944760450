import React, { Fragment } from "react";
import InputField from "../../fields/input-field/index.component";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import Typography from "../../typography/index.component";
import AppButton from "../../button/index.component";
import {
  COLOR_WHITE,
  COLOR_DARK_GREY,
  COLOR_BLACK,
} from "../../../constants/colors.constants";

import AddressHomeIcon from "../../../assets/icons/address-home.icon";
import AddressWorkIcon from "../../../assets/icons/address-work.icon";
import AddressOthersIcon from "../../../assets/icons/address-others.icon";
import {
  ADDRESS_TAG_HOME,
  ADDRESS_TAG_OTHERS,
  ADDRESS_TAG_WORK,
} from "../../../constants/enums.constants";
import { bright_90, increaseBrightness } from "common/helpers/adjustColor";

export default function AddAddressForm({
  onSubmit,
  primaryColor,
  primaryTextColor,
  secondaryTextColor,
  activeTab,
  setActiveTab,
  isSaveAddressLoading,
  onFormClick,
}) {
  const { register, handleSubmit, errors } = useForm();
  const { t } = useTranslation();

  return (
    <Fragment>
      <form onClick={onFormClick} className="add-address-form">
        <InputField
          refForm={register({ required: true })}
          type="text"
          name="addressLine1"
          errors={errors}
          label={
            <Typography
              variant="h4"
              weight="bold"
              fontColor={secondaryTextColor}
              isRequired={true}
            >
              {t("profile.addressLine1")}
            </Typography>
          }
          helperText={t("common.requiredFieldHelperText")}
          primaryTextColor={secondaryTextColor}
          inputProps={{
            defaultValue: "",
          }}
        />
        <InputField
          data-parent="form"
          refForm={register}
          type="text"
          name="addressLine2"
          errors={errors}
          label={
            <Typography
              variant="h4"
              weight="bold"
              fontColor={secondaryTextColor}
            >
              {t("profile.addressLine2")}
            </Typography>
          }
          primaryTextColor={secondaryTextColor}
          inputProps={{
            defaultValue: "",
          }}
        />
        <InputField
          refForm={register({ required: false })}
          type="text"
          name="city"
          errors={errors}
          label={
            <Typography
              variant="h4"
              weight="bold"
              fontColor={secondaryTextColor}
            >
              {t("profile.city")}
            </Typography>
          }
          helperText={t("common.requiredFieldHelperText")}
          primaryTextColor={secondaryTextColor}
          inputProps={{
            defaultValue: "",
          }}
        />
        <InputField
          refForm={register({ required: false })}
          type="number"
          name="pincode"
          errors={errors}
          label={
            <Typography
              variant="h4"
              weight="bold"
              fontColor={secondaryTextColor}
            >
              {t("profile.pincode")}
            </Typography>
          }
          helperText={t("common.requiredFieldHelperText")}
          primaryTextColor={secondaryTextColor}
          inputProps={{
            defaultValue: "",
          }}
        />

        <Typography
          className="address-location-tag-label"
          variant="h4"
          weight="semiBold"
          fontColor={secondaryTextColor}
        >
          {t("sidebar.locationTag")}
        </Typography>

        <div className="address-tag-tabs">
          <AddressTagTab
            active={activeTab === ADDRESS_TAG_HOME}
            primaryColor={primaryColor}
            onClick={() => setActiveTab(ADDRESS_TAG_HOME)}
            backgroundColor={increaseBrightness(primaryColor, bright_90)}
          >
            <AddressHomeIcon
              className="address-icon"
              outlineColor={
                activeTab === ADDRESS_TAG_HOME ? COLOR_BLACK : COLOR_DARK_GREY
              }
              fill={
                activeTab === ADDRESS_TAG_HOME
                  ? increaseBrightness(primaryColor, bright_90)
                  : COLOR_WHITE
              }
            />
            <Typography
              variant="h4"
              weight="semiBold"
              fontColor={
                activeTab === ADDRESS_TAG_HOME ? COLOR_BLACK : COLOR_DARK_GREY
              }
            >
              {t("common.home")}
            </Typography>
          </AddressTagTab>

          <AddressTagTab
            active={activeTab === ADDRESS_TAG_WORK}
            primaryColor={primaryColor}
            onClick={() => setActiveTab(ADDRESS_TAG_WORK)}
            backgroundColor={increaseBrightness(primaryColor, bright_90)}
          >
            <AddressWorkIcon
              className="address-icon"
              outlineColor={
                activeTab === ADDRESS_TAG_WORK ? COLOR_BLACK : COLOR_DARK_GREY
              }
              fill={
                activeTab === ADDRESS_TAG_WORK
                  ? increaseBrightness(primaryColor, bright_90)
                  : COLOR_WHITE
              }
            />
            <Typography
              variant="h4"
              weight="semiBold"
              fontColor={
                activeTab === ADDRESS_TAG_WORK ? COLOR_BLACK : COLOR_DARK_GREY
              }
            >
              {t("common.work")}
            </Typography>
          </AddressTagTab>

          <AddressTagTab
            active={activeTab === ADDRESS_TAG_OTHERS}
            primaryColor={primaryColor}
            onClick={() => setActiveTab(ADDRESS_TAG_OTHERS)}
            backgroundColor={increaseBrightness(primaryColor, bright_90)}
          >
            <AddressOthersIcon
              className="address-icon"
              outlineColor={
                activeTab === ADDRESS_TAG_OTHERS ? COLOR_BLACK : COLOR_DARK_GREY
              }
              fill={
                activeTab === ADDRESS_TAG_OTHERS
                  ? increaseBrightness(primaryColor, bright_90)
                  : COLOR_WHITE
              }
            />
            <Typography
              variant="h4"
              weight="semiBold"
              fontColor={
                activeTab === ADDRESS_TAG_OTHERS ? COLOR_BLACK : COLOR_DARK_GREY
              }
            >
              {t("common.others")}
            </Typography>
          </AddressTagTab>
        </div>

        {activeTab === ADDRESS_TAG_OTHERS && (
          <InputField
            type="text"
            refForm={register({ required: true })}
            name="label"
            errors={errors}
            label={
              <Typography
                variant="h4"
                weight="bold"
                fontColor={primaryTextColor}
                isRequired={true}
              >
                {t("profile.label")}
              </Typography>
            }
            primaryTextColor={primaryTextColor}
            helperText={t("common.requiredFieldHelperText")}
            inputProps={{
              defaultValue: "",
            }}
          />
        )}
      </form>
      <AppButton
        variant="contained"
        isLoading={isSaveAddressLoading}
        className="save-button"
        buttonColor={primaryColor}
        onClickCallback={handleSubmit(onSubmit)}
      >
        <Typography
          variant="h3"
          weight="semiBold"
          className="save-button-text"
          fontColor={COLOR_WHITE}
        >
          {t("sidebar.confirmAndProceed")}
        </Typography>
      </AppButton>
    </Fragment>
  );
}

const AddressTagTab = ({
  children,
  active,
  primaryColor,
  backgroundColor,
  onClick,
}) => {
  return (
    <div
      style={
        active
          ? { borderColor: primaryColor, backgroundColor: backgroundColor }
          : {}
      }
      className="address-tag-tab"
      onClick={onClick}
    >
      {children}
    </div>
  );
};
