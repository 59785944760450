import React, { useEffect, forwardRef } from "react";
import { useTranslation } from "react-i18next";
import ResponsiveHelper from "common/helpers/responsive.helper";
import { Dialog, Drawer, makeStyles, Slide } from "@material-ui/core";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import configSelector from "common/store/selectors/configSelector";
import upsdkSelector from "common/store/selectors/upsdkSelector";
import fetchUser from "common/store/actions/auth";
import { setSelectedAddress } from "common/store/actions/ui";
import {
  ADD_ADDRESS_VIEW,
  CHOOSE_ADDRESS_VIEW,
} from "../../constants/enums.constants";
import AddAddressView from "./add-address/index.component";
import ChooseAddressView from "./choose-address/index.component";
import useRouteHook from "../../hooks/useRoute.hook";

import "./index.component.scss";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles({
  dialog: {
    position: "absolute",
    bottom: 0,
    borderTopRightRadius: "16px",
    borderTopLeftRadius: "16px",
  },
});

function AddressFlow({
  config,
  userData,
  subLocality,
  bizInfo,
  fetchUser,
  activeAddressView,
  closeAddressViewFlow,
  setSelectedAddress,
  selectedAddress,
  fulfilmentType,
  selectedStore,
}) {
  const classes = useStyles();
  const { i18n } = useTranslation();
  const screenWidth = window.innerWidth;
  const isMobileView = ResponsiveHelper.isMobile(screenWidth);
  const primaryTextColor = configSelector.getPrimaryTextColor({ config });
  const primaryColor = configSelector.getPrimaryColor({ config });
  const secondaryTextColor = configSelector.getSecondaryTextColor({ config });
  const { historyPush, pathname } = useRouteHook();
  const isAddAddressView = activeAddressView === ADD_ADDRESS_VIEW;
  const isChooseAddressView = activeAddressView === CHOOSE_ADDRESS_VIEW;
  const handleFlowComplete = () => {
    historyPush("/checkout");
    closeAddressViewFlow();
  };

  useEffect(() => {
    /**
     * Close dialogs and drawers on route change
     */
    closeAddressViewFlow();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderView = () => {
    if (isChooseAddressView) {
      return (
        <ChooseAddressView
          userData={userData}
          primaryColor={primaryColor}
          primaryTextColor={primaryTextColor}
          config={config}
          subLocality={subLocality}
          bizInfo={bizInfo}
          secondaryTextColor={secondaryTextColor}
          fetchUser={fetchUser}
          closeAddressViewFlow={closeAddressViewFlow}
          setSelectedAddress={setSelectedAddress}
          selectedAddress={selectedAddress}
          handleFlowComplete={handleFlowComplete}
          isMobileView={isMobileView}
        />
      );
    }
    return (
      <AddAddressView
        primaryColor={primaryColor}
        primaryTextColor={primaryTextColor}
        config={config}
        subLocality={subLocality}
        bizInfo={bizInfo}
        secondaryTextColor={secondaryTextColor}
        fetchUser={fetchUser}
        closeAddressViewFlow={closeAddressViewFlow}
        handleFlowComplete={handleFlowComplete}
        userData={userData}
        setSelectedAddress={setSelectedAddress}
        fulfilmentType={fulfilmentType}
        selectedStore={selectedStore}
        isMobileView={isMobileView}
      />
    );
  };

  return isMobileView ? (
    <Dialog
      keepMounted
      classes={{
        paper: classes.dialog,
      }}
      open={isAddAddressView || isChooseAddressView}
      fullScreen={true}
      aria-labelledby="address-dialog"
      onClose={closeAddressViewFlow}
      TransitionComponent={Transition}
    >
      {renderView()}
    </Dialog>
  ) : (
    <Drawer
      classes={{ paper: "side-bar-wrapper address-sidebar" }}
      anchor={i18n.dir() === "rtl" ? "left" : "right"}
      open={isAddAddressView || isChooseAddressView}
      onClose={closeAddressViewFlow}
    >
      {renderView()}
    </Drawer>
  );
}

function mapStateToProps(state) {
  return {
    config: state.config,
    upsdk: state.upsdk,
    userData: state.auth.data,
    subLocality: state.ui.subLocality,
    bizInfo: upsdkSelector.getBizInfo(state),
    selectedAddress: state.ui.selectedAddress,
    fulfilmentType: upsdkSelector.getFullfilment(state),
    selectedStore: upsdkSelector.getStore(state),
  };
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchUser: fetchUser,
      setSelectedAddress: setSelectedAddress,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(AddressFlow);
