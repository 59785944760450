import ROUTES_ARRAY from "../constants/routes.constants";
class ScreenHelper {
  static isMenuScreen(customPages, pathname, landingScreenEnabled = false) {
    const customRoutes = (customPages || []).map((page) => page.path);
    let allRoutesExceptMenuRoute = [...customRoutes, ...ROUTES_ARRAY];
    let isMenuScreenUrl = true;

    for (let i = 0; i < allRoutesExceptMenuRoute.length; i++) {
      if (pathname.startsWith(allRoutesExceptMenuRoute[i])) {
        isMenuScreenUrl = false;
        break;
      }
    }

    /**
     * Makes sure menu in navigator is not highlighted on landing screen
     */
    if (landingScreenEnabled && pathname === "/") {
      isMenuScreenUrl = false;
    }

    return isMenuScreenUrl;
  }

  static isItemDetailsScreen(pathname) {
    return pathname.startsWith("/item-list");
  }

  static isFavouritesScreen(pathname) {
    return pathname.startsWith("/favourites");
  }

  static isPastOrdersScreen(pathname) {
    return pathname.startsWith("/past-orders");
  }

  static isCartScreen(pathname) {
    return pathname.startsWith("/cart");
  }

  static isCheckoutScreen(pathname) {
    return pathname.startsWith("/checkout");
  }

  static isCouponsScreen(pathname) {
    return pathname.startsWith("/coupons");
  }

  static isReferralScreen(pathname) {
    return pathname.startsWith("/by_ref");
  }

  static isWalletScreen(pathname) {
    return pathname.startsWith("/wallet");
  }

  static isReferAndEarnScreen(pathname) {
    return pathname.startsWith("/refer-and-earn");
  }

  static isProfileScreen(pathname) {
    return pathname.startsWith("/profile");
  }

  static isCustomScreen(pathname, customPath) {
    return pathname.startsWith(customPath);
  }
}

export default ScreenHelper;
