import dayjs from "dayjs";

export function getOffsetTime({ fulfillmentType, store, biz, dateTime }) {
  if (!store || !biz) {
    return 0;
  }
  let offset;
  if (fulfillmentType === "delivery") {
    offset =
      store.delivery_min_offset_time || biz.delivery_min_offset_time || 0;
  } else {
    offset = store.pickup_min_offset_time || biz.pickup_min_offset_time || 0;
  }
  let tempOffset = Math.round(offset);

  if (dateTime) {
    let now = dayjs().add(tempOffset, "milliseconds");
    let diff = now.diff(dateTime, "milliseconds");
    if (diff < 1) {
      tempOffset = 0;
    } else {
      tempOffset = diff;
    }
  }

  return tempOffset;
}
